import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import SettingsProfile from './Profile/SettingsProfile';

import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';
import { fetchBrandDeveloperKey } from '../../APIClient/brands';

import {
  updateCurrentUser,
  addTagsToUser,
  deleteTagsForUser,
  connectInstagram,
  disconnectSocialAccount,
  connectStripeAccount,
  setAddress,
  logoutUser,
  syncUserTier,
  updateUserSettings
} from '../../Actions/UserActions';
import {
  updateBrand,
  updateBrandSettings,
  saveShopifyIntegration,
  updateBrandIntegration,
  syncShopifyScopesForBrandId,
  rotateBrandDeveloperKey
} from '../../Actions/BrandActions';
import { openAddressModal, openConnectInstagramModal } from '../../Actions/UIActions';

import { insertMetaTags } from '../../Helpers/seo_helpers';
import { getBrandId, isBrand, isLoggedIn } from '../../Helpers/user_helpers';
import { isSimulatingUser } from '../../Helpers/user_helpers';

import './Settings.scss';

class Settings extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    updateCurrentUser: PropTypes.func.isRequired,
    addTagsToUser: PropTypes.func.isRequired,
    deleteTagsForUser: PropTypes.func.isRequired,
    connectInstagram: PropTypes.func.isRequired,
    disconnectSocialAccount: PropTypes.func.isRequired,
    connectStripeAccount: PropTypes.func.isRequired,
    saveShopifyIntegration: PropTypes.func.isRequired,
    updateBrandIntegration: PropTypes.func.isRequired,
    syncShopifyScopesForBrandId: PropTypes.func.isRequired,
    setAddress: PropTypes.func.isRequired,
    updateBrand: PropTypes.func.isRequired,
    updateBrandSettings: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    syncUserTier: PropTypes.func.isRequired,
    openAddressModal: PropTypes.func.isRequired,
    openConnectInstagramModal: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired
  };

  state = {
    selectedTab: 'profile',
    developerKey: ''
  };

  componentDidMount() {
    if (isBrand(this.props.user)) {
      const Brand_id = getBrandId(this.props.user);
      if (Brand_id) {
        fetchBrandDeveloperKey(Brand_id)
          .then(res => this.setState({ developerKey: res.developerKey }))
          .catch(() => window.ALERT.error('Error fetching brand developer key'));
      }
    }
  }

  render() {
    const { user, ui, manager } = this.props;
    if (!isLoggedIn(user)) return <RequiresLoginPanel />;
    return (
      <div className='settings-outer-container'>
        {insertMetaTags({ title: 'Account Settings', description: '', image: '' })}
        <div className='settings-body-inner-container'>
          <SettingsProfile
            user={user.profile}
            ui={ui}
            manager={manager}
            isSimulatingUser={isSimulatingUser(user)}
            connectInstagram={this.props.connectInstagram}
            disconnectSocialAccount={this.props.disconnectSocialAccount}
            connectStripeAccount={this.props.connectStripeAccount}
            saveShopifyIntegration={this.props.saveShopifyIntegration}
            updateBrandIntegration={this.props.updateBrandIntegration}
            syncShopifyScopesForBrandId={this.props.syncShopifyScopesForBrandId}
            syncUserTier={this.props.syncUserTier}
            setAddress={this.props.setAddress}
            logoutUser={this.props.logoutUser}
            updateCurrentUser={this.props.updateCurrentUser}
            addTagsToUser={this.props.addTagsToUser}
            deleteTagsForUser={this.props.deleteTagsForUser}
            updateBrand={this.props.updateBrand}
            updateBrandSettings={this.props.updateBrandSettings}
            openAddressModal={this.props.openAddressModal}
            openConnectInstagramModal={this.props.openConnectInstagramModal}
            developerKey={this.state.developerKey}
            rotateBrandDeveloperKey={this.props.rotateBrandDeveloperKey}
            updateUserSettings={this.props.updateUserSettings}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user, manager, ui } = state;
  return { user, manager, ui };
};

export default connect(mapStateToProps, {
  updateCurrentUser,
  updateBrand,
  updateBrandSettings,
  addTagsToUser,
  deleteTagsForUser,
  setAddress,
  connectInstagram,
  connectStripeAccount,
  saveShopifyIntegration,
  updateBrandIntegration,
  syncShopifyScopesForBrandId,
  disconnectSocialAccount,
  logoutUser,
  syncUserTier,
  openAddressModal,
  openConnectInstagramModal,
  updateUserSettings,
  rotateBrandDeveloperKey
})(withRouter(Settings));
