import _ from 'lodash';
import slugify from 'slugify';
import { faSquare, faRectangleWide, faCameraAlt, faCheckSquare } from '@fortawesome/pro-light-svg-icons';
import arrayMove from 'array-move';
import { isPublicPage } from './helpers';

export const getQuestionsFromConsult = consult => getElementsFromContent(consult.content);

export const getConsultUrl = consult => {
  return `${isPublicPage() ? '/public' : ''}/consults/${consult.id}/${slugify(consult.title)}`;
};

export const getConsultResultUrl = result => {
  return `/consults/results/${result.id}`;
};

export const getConsultCoverImages = () =>
  [
    'general_1.png',
    'general_2.png',
    'general_3.png',
    'skincare_1.png',
    'skincare_2.png',
    'skincare_3.png',
    'skincare_4.png',
    'skincare_5.png',
    'skincare_6.png',
    'skincare_7.png',
    'skincare_8.png',
    'skincare_9.png',
    'skincare_10.png',
    'skincare_11.png',
    'skincare_12.png',
    'skincare_13.png',
    'skincare_14.png',
    'skincare_15.png',
    'skincare_16.png',
    'skincare_17.png',
    'skincare_18.png',
    'skincare_19.png',
    'skincare_20.png',
    'skincare_21.png',
    'skincare_22.png',
    'skincare_23.png',
    'skincare_24.png',
    'makeup_1.png',
    'makeup_2.png',
    'makeup_3.png',
    'makeup_4.png',
    'makeup_5.png',
    'makeup_6.png',
    'makeup_7.png',
    'makeup_8.png',
    'makeup_9.png',
    'makeup_10.png',
    'mens_1.png',
    'wellness_1.png',
    'fragrance_1.png',
    'fragrance_2.png',
    'fragrance_3.png',
    'hair_1.png',
    'hair_2.png',
    'hair_3.png',
    'accessories_1.png',
    'accessories_2.png',
    'accessories_3.png',
    'fashion_1.jpg',
    'fashion_2.jpg',
    'fashion_3.jpg'
  ].map(file => `https://static.shopmy.us/Consult_Images/${file}`);

export const encodeContent = content => JSON.stringify(content || {});
export const decodeContent = content => (content ? JSON.parse(content) : {});
export const generateContentElementId = () => Math.floor(new Date().getTime() / 100);
export const getElementsFromContent = content => {
  const obj = decodeContent(content);
  return obj.elements || [];
};

export const getEmptyConsultResponse = () => JSON.stringify({ elements: [] });

export const addElementToContent = (element, content) => {
  const contentObject = decodeContent(content);
  const elements = contentObject.elements || [];
  const newElements = [...elements, { ...element, id: generateContentElementId() }];
  return encodeContent({
    ...contentObject,
    elements: newElements
  });
};

export const deleteElementFromContent = (element, content) => {
  const contentObject = decodeContent(content);
  const elements = contentObject.elements || [];
  const newElements = elements.filter(el => el.id !== element.id);
  return encodeContent({
    ...contentObject,
    elements: newElements
  });
};

export const updateElementInContent = (element, updates, content) => {
  const contentObject = decodeContent(content);
  const elements = contentObject.elements || [];
  const newElements = elements.map(el => (el.id === element.id ? { ...element, ...updates } : el));
  return encodeContent({
    ...contentObject,
    elements: newElements
  });
};

export const moveElementIndices = (content, oldIndex, newIndex) => {
  const contentObject = decodeContent(content);
  return encodeContent({
    ...contentObject,
    elements: arrayMove(contentObject.elements, oldIndex, newIndex)
  });
};

export const hasNewContent = (response, content) => {
  const responseParts = getElementsFromContent(response);
  const contentParts = getElementsFromContent(content);
  if (responseParts.length !== contentParts.length) return true;

  const responseQuestions = _.map(responseParts, 'question');
  const contentQuestions = _.map(contentParts, 'question');
  if (_.find(contentQuestions, q => !responseQuestions.includes(q))) return true;

  const responseOptionCount = _.flatten(_.filter(_.map(responseParts, 'options'))).length;
  const contentOptionCount = _.flatten(_.filter(_.map(contentParts, 'options'))).length;
  if (responseOptionCount !== contentOptionCount) return true;
  return false;
};

export const getTagFromString = string => _.first(getTagsFromStrings(string));
export const getTagsFromStrings = string => {
  /* Tag format = {{Skincare}} */
  const parts = _.split(string, '{{');
  const tags = parts.slice(1).map(part => _.first(part.split('}}')));
  return tags;
};

export const formatPublicConsultDisplay = display => {
  /*
    When you add {{Skincare}} to the display value it will not be displayed to the public but will be seen as a "tag".

    ** Bit of an easter egg
  */
  const parts = _.split(display, '{{');
  const displayParts = parts.map(part => _.last(part.split('}}')));
  return displayParts.join('');
};

export const getIconForType = type =>
  ({
    LONG_QUESTION: faSquare,
    SHORT_QUESTION: faRectangleWide,
    MULTI_CHOICE_QUESTION: faCheckSquare,
    MEDIA_UPLOAD: faCameraAlt
  }[type]);

export const getTemplateElements = () => [
  /*
    When adding new key:value pairs to the extra section, make sure to use the negative. This will ensure old forms will be able
    to correctly preset. For example, instead of adding shouldHideX = true, use shouldShowX = false, this way any null values will 
    match with the default.

    Extra: default parameters for new item.
  */
  { display: 'Long Response', type: 'LONG_QUESTION', extra: { question: '', answer: '', placeholder: '' } },
  { display: 'Short Response', type: 'SHORT_QUESTION', extra: { question: '', answer: '', placeholder: '' } },
  {
    display: 'Multiple Choice',
    type: 'MULTI_CHOICE_QUESTION',
    extra: {
      options: [
        {
          id: generateContentElementId(),
          question: '',
          checked: false
        }
      ],
      onlyOneSelection: false
    }
  },
  { display: 'Media Upload', type: 'MEDIA_UPLOAD', extra: { mediaUrls: '' } }
];
