import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import './EarningsPortalReferrals.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '../General/Tooltip';

import { getUserId, getReferrals, getReferringUser, getUsername } from '../../Helpers/user_helpers';
import { REFERRAL_LENGTH_DAYS, REFERRAL_PAYOUT_RATE, REFERRAL_LENGTH_MONTHS_DISPLAY } from '../../Helpers/referral_helpers';
import { getUserTierDisplay } from '../../Helpers/tier_helpers';
import { getPrettyPriceDisplay } from '../../Helpers/formatting';

const EarningsPortalReferrals = props => {
  const { user, payoutsAreLoading } = props;

  const [referralsVisible, setReferralsVisible] = useState(5);

  const referrals = useMemo(() => {
    const { user } = props;
    const referrals = getReferrals(user);
    const referringUser = getReferringUser(user);
    const referralEarnings = _.get(user, 'payouts.payout_summaries.referralTotals', {});
    const sortedReferrals = _.concat(_.orderBy(referrals, 'createdAt', 'desc'), referringUser ? user.profile : []);
    return sortedReferrals.map(referral => {
      const earnings = _.get(referralEarnings, referral.id, {});
      return {
        ...referral,
        ...earnings
      };
    });
  }, [user]);
  const hasNoReferrals = referrals.length === 0;
  const hasMoreReferrals = referrals.length > referralsVisible;
  const gridTemplateColumns = '1fr 1fr 1fr 1fr 1fr 1fr';

  if (payoutsAreLoading || hasNoReferrals) return null;
  return (
    <div className='earnings-portal-referrals-outer'>
      <div className='earnings-portal-referrals-inner'>
        <div className='earnings-portal-section-header'>
          <div>
            <div className='earnings-portal-section-title'>Referrals</div>
            <div className='earnings-portal-section-subtitle'>
              Give and earn additional {REFERRAL_PAYOUT_RATE}% of all commissions on referred users for their first {REFERRAL_LENGTH_MONTHS_DISPLAY}{' '}
              months using your referral page.
            </div>
          </div>
          <div>
            <a
              href={`https://shopmy.us/join/${getUsername(user)}`}
              target='_blank'
              rel='noopener nofollow noreferrer'
              className='earnings-portal-section-button'
            >
              My Referral Page
            </a>
          </div>
        </div>

        <div className='earnings-portal-table'>
          <div className='earnings-portal-table-header' style={{ gridTemplateColumns }}>
            <div className='earnings-portal-table-cell'>Joined</div>
            <div className='earnings-portal-table-cell'>Name</div>
            <div className='earnings-portal-table-cell'>Bonus Earned</div>
            <div className='earnings-portal-table-cell'>Bonus Locked</div>
            <div className='earnings-portal-table-cell'>Bonus Paid</div>
            <div className='earnings-portal-table-cell'>Creator Tier</div>
          </div>

          <div className='earnings-portal-table-body'>
            {referrals.slice(0, referralsVisible).map((referral, idx) => {
              const { createdAt, name, username, tier, is_valid, user_payout_total, user_payout_total_locked, user_payout_total_paid } = referral;
              const daysSinceAdded = moment().diff(createdAt, 'days');
              const isReferralWindowComplete = daysSinceAdded > REFERRAL_LENGTH_DAYS;
              const isYou = getUserId(user) === referral.id;

              return (
                <div key={idx} className={cn('earnings-portal-table-row', { inactive: isReferralWindowComplete })} style={{ gridTemplateColumns }}>
                  <div>{moment(createdAt).format('MMM Do, YYYY')}</div>
                  {isYou ? (
                    <div className='earnings-portal-table-cell with-icon'>
                      Your 10% Referral Bonus
                      <Tooltip
                        message={`This represents the additional 10% you earn since you signed up through a referral link from ${
                          getReferringUser(user)?.name
                        }`}
                        getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>}
                      />
                    </div>
                  ) : (
                    <a href={`/${username}`} target='_blank' rel='noopener nofollow noreferrer' className='earnings-portal-table-cell'>
                      {name}
                    </a>
                  )}
                  <div className='earnings-portal-table-cell'>{user_payout_total ? getPrettyPriceDisplay(user_payout_total) : '—'}</div>
                  <div className='earnings-portal-table-cell'>{user_payout_total_locked ? getPrettyPriceDisplay(user_payout_total_locked) : '—'}</div>
                  <div className='earnings-portal-table-cell'>{user_payout_total_paid ? getPrettyPriceDisplay(user_payout_total_paid) : '—'}</div>
                  <div className='earnings-portal-table-cell'>
                    {getUserTierDisplay(tier.tier)}
                    {!is_valid && (
                      <Tooltip
                        message={`This user does not meet the threshold to be considered a valid referral, their score is currently ${tier.score.toFixed(
                          0
                        )}/100.`}
                        getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>}
                      />
                    )}
                  </div>
                </div>
              );
            })}

            {(referrals.length > 5 || !payoutsAreLoading) && (
              <div className='earnings-portal-table-row' style={{ gridTemplateColumns: '1fr' }}>
                <div className='show-more' onClick={() => (hasMoreReferrals ? setReferralsVisible(referralsVisible + 5) : setReferralsVisible(5))}>
                  {hasMoreReferrals ? 'Show More' : 'Show Less'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EarningsPortalReferrals.propTypes = {
  user: PropTypes.object.isRequired,
  payoutsAreLoading: PropTypes.bool.isRequired
};

export default EarningsPortalReferrals;
