import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import Switch from 'react-switch';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './ConfirmPrompt.scss';

import Loader from '../Loader/Loader';
import DateSelector from '../General/DateSelector';

const ConfirmPrompt = props => {
  /*
    Examples:

      Multi-line:
        import { confirmAlert } from 'react-confirm-alert'; 
        import ConfirmPrompt from '../General/ConfirmPrompt';
      
       confirmAlert({
          customUI: ({ onClose }) => (
            <ConfirmPrompt
              header='Add a message.'
              subheader='Message is about this.'
              placeholder='Add your message here.'
              onCancel={onClose}
              customInputFields={[
                { display: 'Question One', placeholder: 'Placeholder One', value: 'value_one', preloaded: 1 },
                { display: 'Question Two', placeholder: 'Placeholder Two', value: 'value_two', isSingleLine: true },
                { display: 'Question Three', placeholder: 'Disabled', value: 'value_three', disabledMsg: 'Cannot edit' },
              ]}
              onSubmit={responseValues => {
                // {value_one: 12, value_two: 15}
              }}
            />
          )
        })

  */
  const {
    isSingleLine,
    customInputFields,
    onCancel,
    preloaded,
    placeholder,
    numRows,
    onSubmit,
    onSubmitAwait,
    submitMustReturnTrueToClose,
    header,
    subheader,
    subheaderElement,
    secondaryBtn,
    submitBtnDisplay,
    cancelBtnDisplay,
    secondaryPanel,
    hideFormFields = false
  } = props;
  const [inputVal, setInputVal] = useState(_.isNil(preloaded) ? '' : preloaded);
  const [focused, setFocused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputVals, setInputVals] = useState(
    customInputFields
      ? customInputFields.reduce((res, data) => {
          const nextRes = { ...res };
          if (data.value) nextRes[data.value] = _.isNil(data.preloaded) ? (data.isBoolean ? false : '') : data.preloaded;
          if (data.preloaded_date_range) {
            nextRes[data.value_date_range[0]] = data.preloaded_date_range?.[0];
            nextRes[data.value_date_range[1]] = data.preloaded_date_range?.[1];
          }
          return nextRes;
        }, {})
      : []
  );
  const updateInputVals = (newVal, value) => {
    const newVals = { ...inputVals };
    newVals[value] = newVal;
    setInputVals(newVals);
  };
  const updateInputValsBulk = updates => {
    const newVals = { ...inputVals };
    Object.keys(updates).forEach(key => (newVals[key] = updates[key]));
    setInputVals(newVals);
  };

  const submitForm = async e => {
    let error;
    e.preventDefault();
    let success;
    if (onSubmitAwait) {
      setIsSubmitting(true);
      try {
        success = await onSubmitAwait(customInputFields ? inputVals : inputVal);
      } catch (err) {
        error = err;
      }
      setIsSubmitting(false);
    } else if (onSubmit) {
      success = onSubmit(customInputFields ? inputVals : inputVal);
    }

    // Allow failure handling within the onSubmit or onSubmitAwait handlers so we don't close and lose all the data
    if (submitMustReturnTrueToClose && !success) return;

    error ? onCancel({ error }) : onCancel();
  };

  const inputFocus = () => setFocused(true);
  const inputBlur = () => setFocused(false);

  return (
    <div
      className={cn('confirm-prompt-outer-container react-confirm-alert-body', {
        focused,
        blurred: !focused,
        'allow-overflow': props.allowOverflow,
        'has-secondary': !!secondaryPanel
      })}
    >
      {secondaryPanel && <div className='secondary-panel'>{secondaryPanel}</div>}
      <div className='main-panel'>
        <h1 className='header'>{header}</h1>
        {subheader && <div className='subheader'>{subheader}</div>}
        {subheaderElement}
        {!hideFormFields && (
          <form onSubmit={submitForm}>
            {customInputFields ? (
              customInputFields.map(
                (
                  {
                    isSingleLine,
                    isBoolean,
                    isDate,
                    isDateRange,
                    numRows,
                    display,
                    subdisplay,
                    label,
                    disabledMsg,
                    placeholder,
                    value,
                    value_date_range
                  },
                  idx
                ) => (
                  <div key={idx} className='custom-input-field-container'>
                    {display && <div className='custom-input-field-display'>{display}</div>}
                    {subdisplay && <div className='custom-input-field-subdisplay'>{subdisplay}</div>}
                    {isSingleLine ? (
                      <div className='input-wrapper' onClick={() => disabledMsg && cogoToast.warn(disabledMsg)}>
                        <input
                          type='text'
                          autoFocus={idx === 0}
                          onFocus={inputFocus}
                          onBlur={inputBlur}
                          placeholder={placeholder || ''}
                          disabled={!!disabledMsg}
                          onChange={({ target }) => updateInputVals(target.value, value)}
                          value={inputVals[value]}
                        />
                      </div>
                    ) : isBoolean ? (
                      <div className='switch-wrapper' onClick={() => disabledMsg && cogoToast.warn(disabledMsg)}>
                        {label && <span className='label'>{label}</span>}
                        <Switch
                          onChange={() => (disabledMsg ? cogoToast.warn(disabledMsg) : updateInputVals(!inputVals[value], value))}
                          checked={inputVals[value]}
                          height={14}
                          width={24}
                          onColor='#333'
                          offColor='#ccc'
                          className='switch'
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                      </div>
                    ) : isDate ? (
                      <div className='date-wrapper'>
                        {label && <span className='label'>{label}</span>}
                        <DateSelector
                          isSingleDate
                          date={inputVals[value]}
                          onDateChange={date => updateInputVals(date, value)}
                          displayFormat={'MMMM Do, YYYY'}
                        />
                      </div>
                    ) : isDateRange ? (
                      <div className='date-wrapper'>
                        {label && <span className='label'>{label}</span>}
                        <DateSelector
                          allowAllDates
                          startDate={inputVals[value_date_range[0]]}
                          endDate={inputVals[value_date_range[1]]}
                          onDateRangeChange={(startDate, endDate) => {
                            const updates = {};
                            startDate && (updates[value_date_range[0]] = startDate);
                            endDate && (updates[value_date_range[1]] = endDate);
                            updateInputValsBulk(updates);
                          }}
                          displayFormat={'MMMM Do, YYYY'}
                        />
                      </div>
                    ) : (
                      <div className='input-wrapper' onClick={() => disabledMsg && cogoToast.warn(disabledMsg)}>
                        <textarea
                          type='text'
                          autoFocus={idx === 0}
                          rows={numRows || (isMobile ? 3 : 10)}
                          placeholder={placeholder || ''}
                          disabled={!!disabledMsg}
                          onClick={() => disabledMsg && cogoToast.warn(disabledMsg)}
                          onChange={({ target }) => updateInputVals(target.value, value)}
                          value={inputVals[value]}
                        />
                      </div>
                    )}
                  </div>
                )
              )
            ) : isSingleLine ? (
              <input
                autoFocus={!isMobile || props.forceAutoFocus}
                onFocus={inputFocus}
                onBlur={inputBlur}
                type='text'
                placeholder={placeholder || ''}
                onChange={({ target }) => setInputVal(target.value)}
                value={inputVal}
              />
            ) : (
              <textarea
                type='text'
                autoFocus={!isMobile || props.forceAutoFocus}
                onFocus={inputFocus}
                onBlur={inputBlur}
                rows={numRows || (isMobile ? 3 : 10)}
                placeholder={placeholder || ''}
                onChange={({ target }) => setInputVal(target.value)}
                value={inputVal}
              />
            )}
          </form>
        )}
        <div className='react-confirm-alert-button-group'>
          <button
            className='cancel-btn cancel'
            onClick={() => {
              onCancel();
            }}
          >
            {cancelBtnDisplay || 'Cancel'}
          </button>
          {secondaryBtn && (
            <button className='cancel-btn' onClick={secondaryBtn.onClick}>
              {secondaryBtn.display}
            </button>
          )}
          <button className={cn('submit-btn', { submitting: isSubmitting })} onClick={submitForm}>
            {submitBtnDisplay || 'Submit'}
            {isSubmitting && <Loader size={48} />}
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmPrompt.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onSubmitAwait: PropTypes.func,
  submitMustReturnTrueToClose: PropTypes.bool,
  header: PropTypes.string,
  subheader: PropTypes.string,
  subheaderElement: PropTypes.object,
  secondaryPanel: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]), // Can come through as false
  placeholder: PropTypes.string,
  submitBtnDisplay: PropTypes.string,
  cancelBtnDisplay: PropTypes.string,
  isSingleLine: PropTypes.bool,
  allowOverflow: PropTypes.bool,
  forceAutoFocus: PropTypes.bool,
  hideFormFields: PropTypes.bool
};

export default ConfirmPrompt;
