import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getUserPayouts, getUserPayments } from '../../Actions/PayoutActions';

const EarningsPortalData = props => {
  const { user, getUserPayouts, getUserPayments, setPaymentsAreLoading, setPayoutsAreLoading } = props;

  // Fetch initial data
  useEffect(() => {
    setPayoutsAreLoading(true);
    setPaymentsAreLoading(true);

    getUserPayouts(user)
      .catch(() => window.ALERT.error('There was an error fetching your payout information. Please refresh and try again.'))
      .finally(() => setPayoutsAreLoading(false));

    getUserPayments(user)
      .catch(() => window.ALERT.error('There was an error fetching your payment information. Please refresh and try again.'))
      .finally(() => setPaymentsAreLoading(false));
  }, [user?.id]);

  return null;
};

EarningsPortalData.propTypes = {
  getUserPayouts: PropTypes.func.isRequired,
  getUserPayments: PropTypes.func.isRequired,
  setPaymentsAreLoading: PropTypes.func.isRequired,
  setPayoutsAreLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  getUserPayouts: getUserPayouts,
  getUserPayments: getUserPayments
})(EarningsPortalData);
