import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import './LookbookProductModalResult.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faPencil, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert';

import Loader from '../../../Loader/Loader';
import { getDomainFromUrl } from '../../../../Helpers/formatting';
import { getBrandIntegrations, getShopifyIntegration } from '../../../../Helpers/user_helpers';
import { isLookbookConnectedToShopify } from '../../../../Helpers/lookbook_helpers';

const LookbookProductModalResult = props => {
  const { result, lookbook, addLookbookItem, removeSelectedProduct, saving, user, isReplaceMode } = props;
  const { primary_image_data, other_image_data, hideFromPublicCatalog } = result; // if from algolia

  const { Lookbook_id } = result;

  const [savingProduct, setSavingProduct] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);

  const title = result.title;
  const price = result.price;
  const retailer = result?.retailer;
  const image = selectedVariant?.image_url || result.image || primary_image_data?.original_image_url || primary_image_data?.image_url;
  const url = selectedVariant?.page_url || result.url;
  const existingLookbookItem = !isReplaceMode
    ? lookbook.items?.find(item => {
        // Catalog Matching
        if (!Lookbook_id) {
          if (url === item.url) return true;
          if (url === item.default_sibling?.url) return true;
          return false;
        }

        // From Old Lookbook Matching
        if (Lookbook_id) {
          if (item.title === result.title) return true;
        }
        return false;
      })
    : null;

  const { showShopifyConnectedInfo, itemShopifyDomain } = useMemo(() => {
    const hasMultipleShopifyDomains = getBrandIntegrations(user).filter(i => i.type === 'shopify').length > 1;

    const resultSiblingsShopifyConnected = other_image_data.every(sibling => sibling.shopifyVariantId);
    const resultDomain = getDomainFromUrl(result.url) || undefined;
    const integrationForItem = getShopifyIntegration(user, resultDomain);

    if (!hasMultipleShopifyDomains) return { showShopifyConnectedInfo: false, itemShopifyDomain: integrationForItem.domain };

    const itemIsShopifyConnected =
      resultSiblingsShopifyConnected && !!(integrationForItem.allowOrderCreation && integrationForItem.allowProductSyncing);

    return { showShopifyConnectedInfo: itemIsShopifyConnected, itemShopifyDomain: integrationForItem.domain };
  }, [user, result]);

  const addFromPreviousLookbookItem = async () => {
    setSavingProduct(true);
    if (saving) return;

    const extraSiblings = result?.siblings?.map(sibling => ({
      shopifyVariantId: sibling?.shopifyVariantId,
      title: sibling?.title,
      image: sibling?.image,
      description: sibling?.description,
      price: sibling?.price,
      url: sibling?.url,
      isDefault: sibling?.isDefault,
      isAvailable: sibling?.isAvailable
    }));
    const data = { item: { ...result }, extraSiblings };
    delete data.item.id;
    delete data.item.createdAt;
    delete data.item.updatedAt;
    delete data.item.variant;
    delete data.item.sortOrderRank;
    delete data.item.Lookbook_id;
    delete data.item.default_sibling;
    delete data.item.siblings;
    for (const key in data.item) if (!data.item[key]) delete data.item[key];
    for (const sibling of data.extraSiblings) delete sibling.id;

    await addLookbookItem(data, { fromPreviousItem: true });
    setSavingProduct(false);
  };

  // If the lookbook is connected to Shopify, we want to confirm if adding a new product will disconnect it (different shopify domain)
  const confirmShopifyIntegrationStateChange = msgOverride => {
    return new Promise((resolve, reject) => {
      const handledByOverride = lookbook?.orderHandledBy;

      if (handledByOverride) {
        resolve();
        return;
      }

      const { isConnectedToShopify, forDomain } = isLookbookConnectedToShopify(user, lookbook);
      const needsConfirmation = isConnectedToShopify && forDomain !== itemShopifyDomain;
      if (!needsConfirmation) {
        resolve();
        return;
      }

      confirmAlert({
        title: 'Are you sure?',
        message:
          msgOverride ||
          `This lookbook is integrated with Shopify for ${forDomain}. Adding this item at a different domain will disconnect this lookbook from automated Shopify order handling.`,
        buttons: [
          { label: 'Cancel', className: 'cancel', onClick: () => reject(new Error('Operation cancelled by user.')) },
          { label: 'Continue', onClick: () => resolve() }
        ],
        onClickOutside: () => reject(new Error('Operation cancelled by user.')),
        onKeypressEscape: () => reject(new Error('Operation cancelled by user.'))
      });
    });
  };

  // If the user has selected a variant, we want to confirm if they want to add all variants, or just the selected one
  const confirmVariantAddition = () => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: 'Just Confirming',
        message: `You have selected the variant "${selectedVariant.tag}". Would you like to only show this, or show all variants (${other_image_data
          .map(d => d.tag)
          .slice(0, 5)
          .join(', ')}${other_image_data.length > 5 ? ', ...' : ''})?`,
        onClickOutside: () => reject(new Error('Operation cancelled by user.')),
        onKeypressEscape: () => reject(new Error('Operation cancelled by user.')),
        buttons: [
          {
            label: 'Cancel',
            className: 'cancel',
            onClick: () => reject(new Error('Operation cancelled by user.'))
          },
          {
            label: 'All Variants',
            onClick: () => resolve('ALL')
          },
          {
            label: `Only ${selectedVariant.tag}`,
            onClick: resolve
          }
        ]
      });
    });
  };

  const addFromCatalogView = async () => {
    setSavingProduct(true);
    if (saving || savingProduct) return;

    if (existingLookbookItem) {
      await removeSelectedProduct(result);
      setSavingProduct(false);
    } else {
      // If they have a variant selected, we want to ask them if they just want to show that variant, or if they want to show all variants

      const handleSave = async (options = {}) => {
        try {
          await confirmShopifyIntegrationStateChange();
          await addLookbookItem(
            {
              item: {
                url: result.url, // Always send the item url
                price,
                title,
                brand: result.brand,
                description: result.description,
                image
              },
              defaultSiblingTitle: selectedVariant?.tag,
              ...options
            },
            { fromCatalog: true }
          );
        } catch (e) {
          // confirmation modal cancelled
        }
      };

      if (selectedVariant) {
        try {
          const variantTestResult = await confirmVariantAddition();
          if (variantTestResult === 'ALL') {
            await handleSave();
          } else {
            await handleSave({ onlyCreateDefaultSibling: true });
          }
        } catch (e) {
          // confirmation modal cancelled
        }
      } else {
        await handleSave();
      }

      setSavingProduct(false);
    }
  };

  const clickToEdit = () => {
    props.openEditModal(existingLookbookItem);
  };

  const INTIAL_SWATCHES_TO_SHOW = 6;
  const [showAllSwatches, setShowAllSwatches] = useState(false);
  const hasMoreSwatches = other_image_data?.length > INTIAL_SWATCHES_TO_SHOW;
  const toggleShowAllSwatches = () => {
    setShowAllSwatches(!showAllSwatches);
  };

  const subheaders = [];
  if (price) subheaders.push(`$${price}`);
  if (hideFromPublicCatalog) subheaders.push('Unpublished');

  return (
    <div className='lookbook-product-modal-result-outer'>
      <div className='lookbook-product-modal-result-inner'>
        <div className='action-buttons'>
          {existingLookbookItem && (
            <div className={cn('edit button secondary')} onClick={clickToEdit}>
              <FontAwesomeIcon icon={faPencil} />
            </div>
          )}
          <div
            className={cn('add button primary', { active: existingLookbookItem })}
            onClick={Lookbook_id ? addFromPreviousLookbookItem : addFromCatalogView}
          >
            {savingProduct ? <Loader size={35} /> : existingLookbookItem ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faPlus} />}
          </div>
        </div>
        <div className='image-container'>
          {image ? <img src={selectedVariant?.image_url || image} alt={title} /> : <div className='no-image'>N/A</div>}
        </div>
        <div className='product-text'>
          <div className='title-container'>
            <a href={url} target='_blank' rel='noreferrer' onClick={e => e.stopPropagation()} className='title'>
              {props.showAllBrands ? `${retailer} | ${title}` : title}
            </a>
            {subheaders.length > 0 && <div className='subtitle'>{subheaders.join(' • ')}</div>}
          </div>
          <div className='sub-content'>
            {showShopifyConnectedInfo && <div className='shopify-connected-info'>{itemShopifyDomain}</div>}
            {other_image_data?.length > 1 && (
              <div className='variation-swatches'>
                {other_image_data.slice(0, showAllSwatches ? other_image_data.length : INTIAL_SWATCHES_TO_SHOW).map((data, idx) => {
                  const { tag, image_url } = data;
                  const active = selectedVariant?.tag === tag;
                  const select = () => setSelectedVariant(active ? null : data);

                  return (
                    <div onClick={select} className={cn('variation-swatch', { active })} key={idx}>
                      {image_url ? <img src={image_url} alt={title} /> : <div className='empty-img' />}
                      <div className='tag'>{tag}</div>
                    </div>
                  );
                })}
                {hasMoreSwatches && (
                  <div onClick={toggleShowAllSwatches} className={cn('variation-swatch show-more')}>
                    <div className='tag'>{showAllSwatches ? 'Show Less' : `${other_image_data.length - INTIAL_SWATCHES_TO_SHOW} more`}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <a href={url} target='_blank' rel='noreferrer' onClick={e => e.stopPropagation()} className='link-to-site'>
          <FontAwesomeIcon icon={faExternalLink} />
        </a>
      </div>
    </div>
  );
};

LookbookProductModalResult.propTypes = {
  user: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  addLookbookItem: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  removeSelectedProduct: PropTypes.func.isRequired,
  showAllBrands: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  isReplaceMode: PropTypes.bool
};

export default LookbookProductModalResult;
