import React, { useState } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import './ConsultImageSelectionModal.scss';

import Modal from '../General/Modal';

import { getConsultCoverImages } from '../../Helpers/consult_helpers';

const ConsultImageSelectionModal = props => {
  const { consult } = props;
  const [selectedFilter, setSelectedFilter] = useState(null);

  const getTagFromImage = img =>
    img.includes('skincare')
      ? 'Skincare'
      : img.includes('general')
      ? 'General'
      : img.includes('makeup')
      ? 'Makeup'
      : img.includes('hair')
      ? 'Hair'
      : img.includes('mens')
      ? "Men's"
      : img.includes('wellness')
      ? 'Wellness'
      : img.includes('fragrance')
      ? 'Fragrance'
      : img.includes('fashion')
      ? 'Fashion'
      : null;

  let [filters, groups] = [[], {}];
  getConsultCoverImages().forEach(img => {
    const tag = getTagFromImage(img);
    if (tag) {
      filters = tag ? _.uniq([...filters, tag]) : filters;
      groups[tag] = [...(groups[tag] || []), img];
    }
  });

  const selectImage = async img => {
    consult.coverImage !== img && (await props.editConsult(consult.id, { coverImage: img }));
    closeAndCleanUp();
  };
  const closeAndCleanUp = () => {
    props.closeModal();
    setSelectedFilter(null);
  };

  return (
    <Modal
      innerClassName='consult-image-selection-outer-modal'
      contentClassName='consult-image-selection-modal'
      showClose
      visible={props.visible}
      close={props.closeModal}
    >
      <div className='section-selection'>
        <div onClick={() => setSelectedFilter(null)} className={cn('filter', { selected: !selectedFilter })}>
          All Images
        </div>
        {filters.map(filter => (
          <div key={filter} onClick={() => setSelectedFilter(filter)} className={cn('filter', { selected: selectedFilter === filter })}>
            {filter}
          </div>
        ))}
      </div>
      <div className='sections'>
        <div className='header'>{selectedFilter || 'Select a Cover Image'}</div>
        {!selectedFilter && (
          <div className='filter-section'>
            <div className='image-options'>
              <div onClick={() => selectImage(null)} className={cn('image-option empty', { selected: !consult?.coverImage })}>
                No Image
              </div>
            </div>
          </div>
        )}
        {consult &&
          filters.map(filter =>
            selectedFilter && selectedFilter !== filter ? null : (
              <div key={filter} className='filter-section'>
                {!selectedFilter && <div className='filter-section-header'>{filter}</div>}
                <div className='image-options'>
                  {groups[filter].map((image, idx) => {
                    const selected = consult && image === consult.coverImage;
                    return (
                      <div onClick={() => !selectImage(image)} key={idx} className={cn('image-option', { selected })}>
                        <img src={image} alt={`Option ${idx}`} />
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          )}
      </div>
    </Modal>
  );
};

ConsultImageSelectionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  consult: PropTypes.object,
  editConsult: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ConsultImageSelectionModal;
