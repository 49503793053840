import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import './AuthModal.scss';

import Loader from '../Loader/Loader';
import ForgotPassword from './ForgotPassword';
import SelectApplicationType from './SelectApplicationType';
import InputActions from '../General/InputActions';

import { getAndRemoveUrlParam, isHomePage, getUrlParam } from '../../Helpers/helpers';
import { isBrand, isManager, isAdmin, getUsername, isLoggedIn, isShopifyUserRequiredForAppAcceptance } from '../../Helpers/user_helpers';
import { registerAppCommand } from '../../Helpers/mobile_helpers';

const IS_TESTING = false;

class AuthModal extends Component {
  static propTypes = {
    starterPanel: PropTypes.oneOf([null, 'login', 'register', 'apply', 'apply-brands', 'invite', 'apply-select-type', 'forgot-password']),
    user: PropTypes.object.isRequired,
    closePanel: PropTypes.func.isRequired,
    loginUser: PropTypes.func.isRequired,
    registerUser: PropTypes.func.isRequired,
    applyToWaitlist: PropTypes.func.isRequired
  };

  state = {
    panel: this.props.starterPanel, // see prop options
    email: IS_TESTING ? `${Math.floor(Math.random() * 1e9)}@gmail.com` : '',
    password: IS_TESTING ? 'abcd1234!!' : '',
    name: IS_TESTING ? 'Test User' : '',
    brandWebsite: '',
    brandLogo: '',
    brandCommissionRate: '',
    username: IS_TESTING ? `${Math.floor(Math.random() * 1e9)}` : '',
    pitch: '',
    code: '',
    socialhandles: '',
    howTheyHeard: window.location.pathname.includes('nyfw') ? 'Billboard' : null,
    isCreatingBrand: false,
    isLoggingIn: false,
    isRegistering: false,
    isApplying: false
  };

  componentDidMount() {
    setTimeout(this.focusOnInput, 150);

    // Pull code from URL if possible
    const code = getAndRemoveUrlParam('code');
    code && this.setState({ code });

    // Pull code from URL if possible
    const email = getAndRemoveUrlParam('email');
    email && this.setState({ email });

    // Allow app to log user in as well
    registerAppCommand('LOGIN_USER', data => {
      this.loginUser(data.email, data.password);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.panel !== prevState.panel) {
      setTimeout(this.focusOnInput, 150);
    }
  }

  focusOnInput = () => {
    if (this.nameInput) this.nameInput.focus();
    else if (this.emailInput) this.emailInput.focus();
  };

  clickToLogIn = event => {
    const { email, password, isLoggingIn } = this.state;
    event.preventDefault();

    if (isLoggingIn) return;

    if (!email) {
      cogoToast.error('Email Field cannot be left blank.');
      return;
    }

    if (!password) {
      cogoToast.error('Password Field cannot be left blank.');
      return;
    }

    if (isLoggedIn(this.props.user)) {
      cogoToast.success('You are already logged in!');
      this.handleAuthRedirect(this.props.user);
      return;
    }
    this.loginUser(email, password);
  };

  loginUser = (email, password) => {
    const { loginUser, closePanel } = this.props;
    this.setState({ isLoggingIn: true });
    loginUser({ email, password }, this.handleAuthRedirect).then(
      ({ user }) => {
        this.setState({ isLoggingIn: false });

        if (user?.id) {
          // successful login
          closePanel && closePanel();
        }
      },
      err => {
        cogoToast.error('There was an issue logging in, please try again.');
        this.setState({ isLoggingIn: false });
      }
    );
  };

  handleRegister = async event => {
    const { registerUser } = this.props;
    const { email, code, password, username, name, brandWebsite, brandLogo, brandCommissionRate, isCreatingBrand, isRegistering } = this.state;
    event.preventDefault();

    if (isLoggedIn(this.props.user)) return cogoToast.warn('You are already logged in!');

    if (isRegistering) return;
    if (!email) return cogoToast.error('Email Field cannot be left blank.');
    if (!password) return cogoToast.error('Password Field cannot be left blank.');
    if (!username) return cogoToast.error('Username Field cannot be left blank.');
    if (!name) return cogoToast.error('Name Field cannot be left blank.');
    if (!code) return cogoToast.error('Please enter your invitation code. If you do not have one, please apply!');
    if (isCreatingBrand && (!brandWebsite || _.isNaN(parseFloat(brandCommissionRate))))
      return cogoToast.error('Brands must have a website and a valid commission rate.');

    this.setState({ isRegistering: true });
    await registerUser(
      {
        email,
        password,
        username,
        name,
        code,
        brandWebsite,
        brandLogo,
        brandCommissionRate
      },
      this.handleAuthRedirect
    );
    this.setState({ isRegistering: false });
    window.__ADD_EVENT__(`ShopMy - Successfully Registered`, { code, email });
  };

  handleAuthRedirect = (user, isRegistering = false) => {
    if (!user) return;
    const isAuth = getUrlParam('code') || getUrlParam('hmac');
    if (isAuth) {
      // Don't redirect so we can complete the authorization
    } else if (isShopifyUserRequiredForAppAcceptance(user)) {
      this.props.history.push('/codes');
    } else if (isBrand(user)) {
      this.props.history.push('/talent');
    } else if (isManager(user)) {
      this.props.history.push('/settings');
    } else {
      if (isRegistering || isHomePage()) {
        this.props.history.push(`/${getUsername(user)}`);
      }
    }
  };

  joinBrandWaitlist = event => this.joinWaitlist(event, 'brands');
  joinWaitlist = (event, type = 'creators') => {
    const { applyToWaitlist } = this.props;
    const { name, email, pitch, socialhandles, brandWebsite, isApplying, howTheyHeard } = this.state;
    event.preventDefault();

    if (isApplying) return;
    if (isLoggedIn(this.props.user)) return cogoToast.warn('You are already logged in!');

    const isBrands = type === 'brands';

    let fullPitch, waitlistObject;
    if (isBrands) {
      if (!name) return cogoToast.error('Please provide your brand name.');
      if (!email) return cogoToast.error('Please provide an email to reach you at.');
      if (!brandWebsite) return cogoToast.error('Please provide your brand website.');
      if (!pitch) return cogoToast.error('Please explain a bit about who you are.');
      fullPitch = pitch;
      waitlistObject = { email, brand_website: brandWebsite, name, pitch, howTheyHeard };
    } else {
      // validate that they have at least one word with minimum 3 characters (split on all whitespace)
      const hasValidPitch = pitch.split(/\s+/).some(word => word.length >= 3);

      if (!name) return cogoToast.error('Please provide your name.');
      if (!email) return cogoToast.error('Please provide an email to reach you at.');
      if (!pitch) return cogoToast.error('Please explain a bit about who you are.');
      if (!hasValidPitch) return cogoToast.error('Please add at least one social handle.');
      fullPitch = `${pitch} || ${socialhandles}`;
      waitlistObject = { email, name, pitch: fullPitch, howTheyHeard };
    }

    this.setState({ isApplying: true });
    applyToWaitlist(waitlistObject)
      .then(({ data }) => {
        const { success, error } = data || {};
        success ? this.setState({ panel: isBrands ? 'brand-apply-success' : 'apply-success' }) : cogoToast.error(error);
        window.__ADD_EVENT__(`ShopMy - Submitted Application`, { email, brandWebsite });
      })
      .catch(errorMsg => {
        const msg = _.isString(errorMsg) && errorMsg.length ? errorMsg : 'Error processing your request';
        cogoToast.error(msg);
      })
      .finally(() => {
        this.setState({ isApplying: false });
      });
  };

  switchPanel = panel => this.setState({ panel });

  getSecondaryActionsPanel = () => {
    const { panel } = this.state;
    if (panel === 'login') {
      return (
        <div className='secondary-actions'>
          <div className='action-header'>Don't have an account?</div>
          <div className='action-subheader'>
            If you have an invitation code, click{' '}
            <span className='action' onClick={() => this.setState({ panel: 'register' })}>
              here
            </span>{' '}
            to create your account, otherwise click{' '}
            <span className='action' onClick={() => this.setState({ panel: 'apply-select-type' })}>
              here
            </span>{' '}
            to apply for an account.
          </div>
        </div>
      );
    } else if (panel === 'register') {
      return (
        <div className='secondary-actions'>
          <div className='action-header'>Don't have an invitation code?</div>
          <div className='action-subheader'>
            Click{' '}
            <span className='action' onClick={() => this.setState({ panel: 'apply' })}>
              here
            </span>{' '}
            to apply to join!
          </div>
        </div>
      );
    } else if (panel === 'apply') {
      return (
        <div className='secondary-actions'>
          <div className='action-header'>Already applied?</div>
          <div className='action-subheader'>
            If you have an account, click{' '}
            <span className='action' onClick={() => this.setState({ panel: 'login' })}>
              here
            </span>{' '}
            to log in, otherwise click{' '}
            <span className='action' onClick={() => this.setState({ panel: 'register' })}>
              here
            </span>{' '}
            to create an account using your invitation code.
          </div>
        </div>
      );
    } else if (panel === 'apply-brands') {
      return (
        <div className='secondary-actions'>
          <div className='action-header'>Have an account?</div>
          <div className='action-subheader'>
            Click{' '}
            <span className='action' onClick={() => this.setState({ panel: 'login' })}>
              here
            </span>{' '}
            to log in.
          </div>
        </div>
      );
    }
  };

  getPasswordInput = () => {
    const { password } = this.state;
    return (
      <div className='search-input-container has-actions'>
        <input
          type={this.state.isPasswordVisible ? 'text' : 'password'}
          className='password-input'
          placeholder='Password'
          onChange={({ target }) => this.setState({ password: target.value })}
          value={password}
          ref={input => {
            this.passwordInput = input;
          }}
        />
        <InputActions
          searchValHidden={!this.state.isPasswordVisible}
          searchVal={password}
          onToggleVisibility={() => {
            this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
          }}
        />
      </div>
    );
  };

  updateUsername = username => {
    const formattedUsername = username.toLowerCase().replace(/[^A-Za-z0-9]/, '');
    this.setState({ username: formattedUsername });
  };

  render() {
    const { user, closePanel, loginUser } = this.props;
    const {
      panel,
      email,
      name,
      pitch,
      code,
      socialhandles,
      howTheyHeard,
      brandWebsite,
      brandLogo,
      brandCommissionRate,
      username,
      isLoggingIn,
      isApplying,
      isRegistering,
      isCreatingBrand
    } = this.state;

    const fullWidth = panel === 'apply-select-type';
    return (
      <div className='auth-modal-outer-container'>
        <div onClick={closePanel} className='auth-modal-fade-container' />
        <div className={cn('auth-modal-inner-container', { 'full-width': fullWidth })}>
          <div onClick={closePanel} className='close-icn'>
            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
          </div>

          {panel === 'login' && (
            <div className='auth-section'>
              <div className='auth-header'>Log in to continue.</div>
              <form onSubmit={this.clickToLogIn}>
                <input
                  type='email'
                  className='email-input'
                  placeholder='Email'
                  onChange={({ target }) => this.setState({ email: target.value })}
                  value={email}
                  ref={input => {
                    this.emailInput = input;
                  }}
                />
                {this.getPasswordInput()}
                <div className='login-btn-container'>
                  <input
                    type='submit'
                    className={cn('login-btn', {
                      loading: isLoggingIn
                    })}
                    value='LOG IN'
                  />
                  {isLoggingIn && <Loader size={70} />}
                </div>
              </form>
              <div
                className='forgot-password'
                onClick={() => {
                  this.setState({ panel: 'forgot-password' });
                }}
              >
                FORGOT YOUR PASSWORD?
              </div>
              {this.getSecondaryActionsPanel()}
            </div>
          )}

          {panel === 'apply' && (
            <div className='auth-section'>
              <div className='auth-header'>Apply to join.</div>
              <div className='auth-subheader'>Fill out the form below and someone from our team will be in touch.</div>
              <form className='flex-center' onSubmit={this.joinWaitlist}>
                <input
                  type='text'
                  className='name-input'
                  placeholder='Name'
                  onChange={({ target }) => this.setState({ name: target.value })}
                  value={name}
                  ref={input => {
                    this.nameInput = input;
                  }}
                />
                <input
                  type='email'
                  className='email-input'
                  placeholder='Email Address'
                  onChange={({ target }) => this.setState({ email: target.value })}
                  value={email}
                  ref={input => {
                    this.emailInputRegister = input;
                  }}
                />
                <textarea
                  id='title'
                  name='title'
                  type='text'
                  value={pitch}
                  rows={4}
                  placeholder='Briefly describe who you are and the types of content you promote regularly on your social channels.'
                  onChange={({ target }) => this.setState({ pitch: target.value })}
                  ref={input => {
                    this.pitchInput = input;
                  }}
                />
                <textarea
                  id='socialhandles'
                  name='socialhandles'
                  type='text'
                  value={socialhandles}
                  className='standard-input'
                  rows='3'
                  placeholder={`Links to your social channels`}
                  onChange={({ target }) => this.setState({ socialhandles: target.value })}
                />
                <input
                  type='text'
                  className='name-input'
                  placeholder='How did you hear about ShopMy?'
                  onChange={({ target }) => this.setState({ howTheyHeard: target.value })}
                  value={howTheyHeard}
                  ref={input => {
                    this.howTheyHeardInputRegister = input;
                  }}
                />
                <div className='login-btn-container'>
                  <input type='submit' className={cn('login-btn', { loading: isApplying })} value='APPLY' />
                  {isApplying && <Loader size={70} />}
                </div>
              </form>
              {this.getSecondaryActionsPanel()}
            </div>
          )}

          {panel === 'apply-brands' && (
            <div className='auth-section'>
              <div className='auth-header'>Request a demo.</div>
              <div className='auth-subheader'>Fill out the form below and someone from our team will be in touch.</div>
              <form className='flex-center' onSubmit={this.joinBrandWaitlist}>
                <input
                  type='text'
                  className='name-input'
                  placeholder='Brand Name'
                  onChange={({ target }) => this.setState({ name: target.value })}
                  value={name}
                  ref={input => {
                    this.nameInput = input;
                  }}
                />
                <input
                  type='text'
                  className='name-input'
                  placeholder='Brand Website'
                  onChange={({ target }) => this.setState({ brandWebsite: target.value })}
                  value={brandWebsite}
                  ref={input => {
                    this.websiteInput = input;
                  }}
                />
                <input
                  type='email'
                  className='email-input'
                  placeholder='Email Address'
                  onChange={({ target }) => this.setState({ email: target.value })}
                  value={email}
                  ref={input => {
                    this.emailInputRegister = input;
                  }}
                />
                <textarea
                  id='title'
                  name='title'
                  type='text'
                  value={pitch}
                  rows={6}
                  placeholder='Briefly describe your business and how you want to work with the platform.'
                  onChange={({ target }) => this.setState({ pitch: target.value })}
                  ref={input => {
                    this.pitchInput = input;
                  }}
                />
                <input
                  type='text'
                  className='name-input'
                  placeholder='How did you hear about ShopMy?'
                  onChange={({ target }) => this.setState({ howTheyHeard: target.value })}
                  value={howTheyHeard}
                  ref={input => {
                    this.howTheyHeardInputRegister = input;
                  }}
                />
                <div className='login-btn-container'>
                  <input type='submit' className={cn('login-btn', { loading: isApplying })} value='REQUEST DEMO' />
                  {isApplying && <Loader size={70} />}
                </div>
              </form>
              {this.getSecondaryActionsPanel()}
            </div>
          )}

          {panel === 'apply-success' && (
            <div className='auth-section'>
              <div className='auth-header'>Thank you for applying!</div>
              <div className='auth-subheader'>
                We will review your application and if you are a good fit for the platform we will send you an invitation code by email! If you have
                anything additional to add to your application, you can email it to us at team@shopmy.us. Our team should be get back to you within
                1-3 business days.
              </div>
              <div className='secondary-actions'>
                <button onClick={closePanel} className='action-btn'>
                  CONTINUE
                </button>
              </div>
            </div>
          )}

          {panel === 'brand-apply-success' && (
            <div className='auth-section'>
              <div className='auth-header'>Thank you!</div>
              <div className='auth-subheader'>We will email you as soon as one of our brand account managers is available!</div>
              <div className='secondary-actions'>
                <button onClick={closePanel} className='action-btn'>
                  CONTINUE
                </button>
              </div>
            </div>
          )}

          {panel === 'register' && (
            <div className='auth-section'>
              <div className='auth-header'>Create an account.</div>
              {isAdmin(user) && (
                <div className='auth-subheader' onClick={() => this.setState({ isCreatingBrand: !isCreatingBrand })}>
                  Creating
                  <div
                    className={cn('option', {
                      selected: !isCreatingBrand
                    })}
                    onClick={() => this.setState({ isCreatingBrand: false })}
                  >
                    User
                  </div>
                  <div
                    className={cn('option', {
                      selected: isCreatingBrand
                    })}
                    onClick={() => this.setState({ isCreatingBrand: true })}
                  >
                    Brand
                  </div>
                </div>
              )}
              <form onSubmit={this.handleRegister}>
                <input
                  type='text'
                  className='name-input'
                  placeholder='Name'
                  onChange={({ target }) => this.setState({ name: target.value })}
                  value={name}
                  ref={input => {
                    this.nameInput = input;
                  }}
                />
                {isCreatingBrand && (
                  <>
                    <input
                      placeholder='Brand Website'
                      onChange={({ target }) => this.setState({ brandWebsite: target.value })}
                      value={brandWebsite}
                    />
                    <input placeholder='Brand Logo URL' onChange={({ target }) => this.setState({ brandLogo: target.value })} value={brandLogo} />
                    <input
                      placeholder='Base Commission Rate'
                      onChange={({ target }) => this.setState({ brandCommissionRate: target.value })}
                      value={brandCommissionRate}
                    />
                  </>
                )}
                <input
                  type='email'
                  className='email-input'
                  placeholder='Email'
                  onChange={({ target }) => this.setState({ email: target.value })}
                  value={email}
                  ref={input => {
                    this.emailInput = input;
                  }}
                />
                <input
                  type='text'
                  className='username-input'
                  placeholder='Username'
                  onChange={({ target }) => this.updateUsername(target.value)}
                  value={username}
                  ref={input => {
                    this.usernameInput = input;
                  }}
                />
                {this.getPasswordInput()}
                <input
                  type='text'
                  className='code-input'
                  placeholder='Invitation Code'
                  onChange={({ target }) => this.setState({ code: target.value })}
                  value={code}
                />
                <div className='login-btn-container'>
                  <input
                    type='submit'
                    className={cn('login-btn', {
                      loading: isRegistering
                    })}
                    value='CREATE ACCOUNT'
                  />
                  {isRegistering && <Loader size={70} />}
                </div>
              </form>
              {this.getSecondaryActionsPanel()}
            </div>
          )}

          {panel === 'forgot-password' && <ForgotPassword backToLogin={() => this.switchPanel('login')} loginUser={loginUser} />}

          {panel === 'apply-select-type' && (
            <SelectApplicationType
              selectBrands={() => this.switchPanel('apply-brands')}
              selectCreators={() => this.switchPanel('apply')}
              goToLogin={() => this.switchPanel('login')}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AuthModal);
