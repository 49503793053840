import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './OpportunityRequestResult.scss';

import {
  getStatusDataForOpportunityRequest,
  getTimingOnOpportunity,
  getCoverImageForOpportunity,
  getDisplayStatsOnOpportunityResult,
  isOpportunityResultInReview,
  isOpportunityResultCompleted,
  isOpportunityResultPaid,
  isOpportunityRequestPastDueDate,
  getOpportunityRequestAdjustedExpectations,
  opportunityHasExpectations
} from '../../Helpers/opportunity_helpers';
import { getPrettyNumber, getPrettyTimeAgoFromNow } from '../../Helpers/formatting';

import Tooltip from '../General/Tooltip';

const OpportunityRequestResult = props => {
  const { request, isFeaturedCard } = props;
  const { id, result, payment_tier, opportunity, brand } = request;
  const { statsLastUpdatedAt, isCompletedAt } = result || {};
  const stats = result?.stats ? JSON.parse(result.stats) : {};
  const { linksExpected, linkingDaysExpected, mentionsExpected, mentionDaysExpected } = getOpportunityRequestAdjustedExpectations(
    opportunity,
    request
  );

  // Statuses
  const isInReview = isOpportunityResultInReview(result);
  const isPaid = isOpportunityResultPaid(result);
  const isCompleted = isOpportunityResultCompleted(result);
  const isPastDueDate = isOpportunityRequestPastDueDate(request);

  // Get General Data
  const statusData = getStatusDataForOpportunityRequest(request);
  const timing = getTimingOnOpportunity(opportunity);
  const hasExpectations = opportunityHasExpectations(opportunity);

  // Get dynamic images to show
  const image = getCoverImageForOpportunity(opportunity, brand);
  const image2 = getCoverImageForOpportunity(opportunity, brand);
  const image3 = getCoverImageForOpportunity(opportunity, brand);
  const validImages = [image, image2, image3].filter(i => i);
  const imageCount = validImages.length;

  // Get Stats
  const resultStats = getDisplayStatsOnOpportunityResult(result, opportunity, { hideEMV: true });

  const additionalCardClasses = { 'featured-card': isFeaturedCard };
  // Get progress UI for card
  let metadata = [];

  if (isInReview) {
    metadata.push('In Review');
  } else if (isCompleted) {
    metadata.push(brand.name);
    hasExpectations && metadata.push('Completed');
  } else if (timing.hasStarted) {
    if (linksExpected) metadata.push(`${stats.links + stats.missingLinks || 0}/${linksExpected} link${linksExpected > 1 ? 's' : ''}`);
    if (linkingDaysExpected)
      metadata.push(
        `${stats.linkingDays + stats.missingLinkingDays || 0}/${linkingDaysExpected} days with link${linkingDaysExpected > 1 ? 's' : ''}`
      );
    if (mentionsExpected)
      metadata.push(`${stats.mentions + stats.missingMentions || 0}/${mentionsExpected} mention${mentionsExpected > 1 ? 's' : ''}`);
    if (mentionDaysExpected)
      metadata.push(
        `${stats.mentionDays + stats.missingMentionDays || 0}/${mentionDaysExpected} day${
          opportunity.mentionDaysExpected > 1 ? 's' : ''
        } with mentions`
      );
    if (!metadata.length) metadata.push(statusData.actionDisplay);
  } else {
    metadata.push(timing.timelineLabel);
  }

  // Get date information
  let keyDateLabel, keyDateDisplay;
  if (timing.hasEnded) {
    keyDateDisplay = moment(opportunity.trackingEndsAt).format('MMM Do');
    keyDateLabel = 'Tracking Ended';
  } else if (timing.endsThisMonth) {
    keyDateDisplay = `${timing.endsInDays} Day${timing.endsInDays > 1 ? 's' : ''}`;
    keyDateLabel = 'Tracking Ends';
  } else if (timing.isActive) {
    keyDateDisplay = moment(opportunity.trackingEndsAt).format('MMM Do');
    keyDateLabel = 'Tracking Ends';
  } else if (timing.startsThisMonth) {
    keyDateDisplay = `${timing.startsInDays} Day${timing.startsInDays > 1 ? 's' : ''}`;
    keyDateLabel = 'Tracking Starts';
  } else if (timing.isNotYetStarted) {
    keyDateDisplay = moment(opportunity.trackingStartsAt).format('MMM Do');
    keyDateLabel = 'Tracking Starts';
  }

  // Preload the opportunity request for fast navigation
  const select = () => props.setActiveOpportunityRequest(request);

  return (
    <Link key={id} onClick={select} to={`/opportunity/${opportunity.id}`} className={cn('opportunity-request-result', additionalCardClasses)}>
      <div className={cn('media-container', `image-count-${imageCount}`, additionalCardClasses)}>
        {validImages.map((image, idx) => {
          return (
            <div key={image + idx} className='media-img-container'>
              <img src={image} alt={opportunity.title} />
            </div>
          );
        })}
      </div>
      <div className={cn('main-container', additionalCardClasses)}>
        <div className='section user-info'>
          {isFeaturedCard && <div className='section-title'>{brand.name}</div>}
          <div className='section-data'>
            <div className='name'>{opportunity.title}</div>
            <div className='metadata'>{metadata.join(' • ')}</div>
          </div>
        </div>
        <div className='section spend'>
          {isFeaturedCard && <div className='section-title'>Earnings</div>}
          <div className='section-stats'>
            <div className='section-stat'>
              {payment_tier.fixedFee && !isPastDueDate ? (
                <div className='value'>${getPrettyNumber(payment_tier.fixedFee)}</div>
              ) : (
                <div className='value empty'>-</div>
              )}
              <div className={cn('label', { empty: !payment_tier.fixedFee })}>
                {isPaid
                  ? `Paid ${moment(isCompletedAt).format('MMM Do')}`
                  : isInReview
                  ? 'In Review'
                  : isPastDueDate
                  ? 'Did not complete'
                  : 'Pending Completion'}
              </div>
            </div>
          </div>
        </div>
        <div className='section results'>
          {isFeaturedCard && (
            <div className='section-title'>
              {timing.startsInDays > 0 ? 'Results' : 'Results'}
              {!!statsLastUpdatedAt && (
                <Tooltip message={`Last updated ${getPrettyTimeAgoFromNow(statsLastUpdatedAt, { longForm: true })}`}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              )}
            </div>
          )}
          <div className='section-stats'>
            {resultStats.map(({ label, value, display, isSort }) => {
              const additionalClasses = { featured: isSort, empty: !value };
              return (
                <div key={label} className={cn('section-stat', additionalClasses)}>
                  <div className={cn('value', additionalClasses)}>{value ? display : '-'}</div>
                  <div className={cn('label', additionalClasses)}>{label}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className='section timing'>
          {isFeaturedCard && <div className='section-title'>Timing</div>}
          <div className={cn('section-stats')}>
            <div className={cn('section-stat')}>
              <div className={cn('value')}>{keyDateDisplay}</div>
              <div className={cn('label')}>{keyDateLabel}</div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

OpportunityRequestResult.propTypes = {
  request: PropTypes.object.isRequired,
  isFeaturedCard: PropTypes.bool
};

export default OpportunityRequestResult;
