import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './ArtistModalControls.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-light-svg-icons';

import Loader from '../Loader/Loader';

import { getCustomRateForUser, getGiftingRequestsForUser, getCodesForUser } from '../../Helpers/brand_helpers';
import { getCodeRateDisplay } from '../../Helpers/formatting';
import { formatUsersForBulkRequests } from '../../Helpers/gifting_helpers';
import { setCustomRate } from '../../Helpers/rate_helpers';

const ArtistModalControls = props => {
  const { talent, analytics, user } = props;
  const requests = getGiftingRequestsForUser(analytics, talent);

  const allCustomCodes = getCodesForUser(analytics, talent);
  const hasMultipleCodes = allCustomCodes.length > 1;
  const customCode = allCustomCodes[0] || null;
  const customRate = getCustomRateForUser(analytics, talent);

  // Configurable Settings
  const openCustomRateModal = () => {
    setCustomRate({
      user,
      artist: talent,
      analytics,
      setCustomCommissionRate: props.setCustomCommissionRate
    });
  };

  // Gifting
  const [isRequestingGifing, setIsRequestingGifing] = useState(false); // Not currently used
  const requestGifting = async () => {
    setTimeout(() => setIsRequestingGifing(false), 1500); // In case of close via modal escape press
    props.openRequestModal({
      params: {
        preselectedUsers: formatUsersForBulkRequests([talent])
      }
    });
  };

  // Codes
  const [isUpdatingCode, setIsUpdatingCode] = useState(false);
  const offerCodeToUser = async () => {
    setIsUpdatingCode(true);
    setTimeout(() => setIsUpdatingCode(false), 1500); // In case of close via modal escape press

    props.openCodesModal({
      params: {
        User_id: talent.id,
        name: talent.name,
        showTemplates: true
      },
      closeCallback: () => setIsUpdatingCode(false)
    });
  };

  return (
    <form className='artist-modal-controls'>
      <div className='actions'>
        {customRate ? (
          <div onClick={openCustomRateModal} className={cn('custom-rate action first-action-btn')}>
            {customRate.rate_returning ? `Update ${customRate.rate}/${customRate.rate_returning}% Rate` : `Update ${customRate.rate}% Rate`}
          </div>
        ) : (
          <div onClick={openCustomRateModal} className={cn('custom-rate action first-action-btn')}>
            Offer Custom Rate
          </div>
        )}
        {customCode ? (
          <div onClick={offerCodeToUser} className={cn('custom-code action duration-action has-code', { loading: isUpdatingCode })}>
            <div className='custom-code-label'>{`${customCode.displayText} for ${getCodeRateDisplay(customCode)} off`}</div>
            {hasMultipleCodes && <div className='custom-code-label-multiple'>+ {allCustomCodes.length - 1}</div>}
            <FontAwesomeIcon icon={faPencil} />
            {isUpdatingCode && <Loader size={60} />}
          </div>
        ) : (
          <div onClick={offerCodeToUser} className={cn('custom-code action duration-action first-action-btn')}>
            Offer Discount Code
          </div>
        )}
        <div onClick={requestGifting} className={cn('gifting action duration-action first-action-btn', { loading: isRequestingGifing })}>
          {requests.length ? 'Send More Gifting' : 'Send Gifting'}
          {isRequestingGifing && <Loader size={60} />}
        </div>
      </div>
    </form>
  );
};

ArtistModalControls.propTypes = {
  user: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  createSamplesRequest: PropTypes.func.isRequired,
  openCodesModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  setCustomCode: PropTypes.func.isRequired,
  setCustomCommissionRate: PropTypes.func.isRequired
};

export default ArtistModalControls;
