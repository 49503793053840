import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExternalLink, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import { isValidPhoneNumber } from 'libphonenumber-js';
import stripeIcon from '../../../static/images/misc/stripe.png';
import { confirmAlert } from 'react-confirm-alert';
import { addEvent } from '../../../APIClient/events';
import { getStripeOnboardingStatus } from '../../../APIClient/stripe';
import { testShopifyIntegration as testShopifyIntegrationAPI } from '../../../APIClient/integrations';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './SettingsProfile.scss';

import icons from '../../../static/icons';

import FacebookSDK from '../../../Components/General/FacebookSDK';
import UserTierPanel from '../../../Components/Settings/UserTierPanel';
import UserManagersPanel from '../../../Components/Settings/UserManagersPanel';
import UserTokensPanel from '../../../Components/Settings/UserTokensPanel';
import UserTagsPanel from '../../../Components/Settings/UserTagsPanel';
import CodeSettingsPanel from '../../../Components/Settings/CodeSettingsPanel';
import ShopifyIntegrationPanel from '../../../Components/Settings/ShopifyIntegrationPanel';
import StripeConfigurationPanel from '../../../Components/Settings/StripeConfigurationPanel';
import BlacklistedCodesPanel from '../../../Components/Settings/BlacklistedCodesPanel';
import CompetitorBrandSettingsPanel from '../../../Components/Settings/CompetitorBrandSettingsPanel';
import RequiresPermissions from '../../../Components/Managers/RequiresPermissions';
import Tooltip from '../../../Components/General/Tooltip';

import { doesUserExist as doesUserExistAPI } from '../../../APIClient/users';

import {
  isBrand,
  isTalent,
  isManager,
  getReferringBrand,
  getSocialAccount,
  validateUsername,
  getBrand,
  isSMSAffiliatePartnerBrand,
  getStripeAccountId,
  getAddress,
  getEmail,
  getName,
  getUsername,
  getUserTier,
  getBrandSettings,
  getBrandUtmParams,
  getTokens,
  needsToCompleteStripeOnboarding,
  isShopifyUserRequiredForAppAcceptance,
  doAllShopifyIntegrationsHavePermission,
  hasAllowedLocationServices
} from '../../../Helpers/user_helpers';
import { openInstagramAuthModal, openYoutubeAuthModal, openTikTokAuthModal } from '../../../Helpers/social_helpers';
import { goToStripeOnboarding, formatStripeRequirement } from '../../../Helpers/stripe_helpers';
import { registerAppCommand } from '../../../Helpers/mobile_helpers';
import { isCurrentlyManaging } from '../../../Helpers/manager_helpers';
import { isSubscribedToFeature } from '../../../Helpers/subscription_helpers';
import { doesTakePercentageFromUser } from '../../../Helpers/brand_helpers';
import { getDisplaySourceFromMerchant, getInitialsForUser, isValidEmail } from '../../../Helpers/formatting';

class SettingsProfile extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    manager: PropTypes.object.isRequired,
    connectStripeAccount: PropTypes.func.isRequired,
    connectInstagram: PropTypes.func.isRequired,
    disconnectSocialAccount: PropTypes.func.isRequired,
    syncUserTier: PropTypes.func.isRequired,
    setAddress: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    saveShopifyIntegration: PropTypes.func.isRequired,
    updateBrandIntegration: PropTypes.func.isRequired,
    syncShopifyScopesForBrandId: PropTypes.func.isRequired,
    addTagsToUser: PropTypes.func.isRequired,
    deleteTagsForUser: PropTypes.func.isRequired,
    updateCurrentUser: PropTypes.func.isRequired,
    updateBrand: PropTypes.func.isRequired,
    updateBrandSettings: PropTypes.func.isRequired,
    openAddressModal: PropTypes.func.isRequired,
    developerKey: PropTypes.string,
    rotateDeveloperKey: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired
  };

  state = {
    name: '',
    username: '',
    email: '',
    requestsEmail: '',
    image: '',
    amazonCode: '',
    skinbetterInviteCode: '',
    oldPassword: '',
    newPassword: '',
    newAddress: '',
    newPhone: '',
    utmParams: {},
    commission_rate: 0,

    // Manager
    managerCompany: '',
    managerPosition: '',

    // shopify
    showShopifySetup: false,
    isTestingShopify: false,
    shopifyTestSuccess: false,
    shopifyShopName: '',
    shopifyPrivateKey: '',
    shopifyAdminAccessToken: '',

    // UI
    isSavingProfile: false,
    isConnectingStripe: false,
    isAddingSocialAccountType: null,
    stripeAccountLinkWarnings: [],

    // Developer Key Privacy
    isKeyVisible: false,

    // Location Data Settings
    allowLocationServices: false
  };

  componentDidMount() {
    const { user } = this.props;
    this.setState({
      name: getName(user) || '',
      username: getUsername(user) || '',
      email: getEmail(user) || '',
      image: _.get(user, 'image') || '',
      commission_rate: this.getBrandCommissionRate(),
      commission_rate_returning: this.getBrandCommissionRateReturning(),
      showingReturningCommissionRateInput: !!this.getBrandCommissionRateReturning(),
      newAddress: getAddress(user)?.address || '',
      newPhone: getAddress(user)?.phone || '',
      amazonCode: _.get(user, 'amazonCode') || '',
      skinbetterInviteCode: _.get(user, 'skinbetterInviteCode') || '',
      allowLocationServices: hasAllowedLocationServices(user),
      managerCompany: _.get(user, 'managerCompany') || '',
      managerPosition: _.get(user, 'managerPosition') || '',

      // Brand Specific
      requestsEmail: _.get(user, 'requestsEmail') || '',
      utmParams: getBrandUtmParams(user),
      developerKey: _.get(user, 'developerKey') || this.props.developerKey || null
    });
    this.checkStripeStatus();
    if (!isBrand(user)) {
      this.syncUserTier();
    }

    // Allow the app to call logout
    registerAppCommand('LOGOUT_USER', data => {
      this.props.logoutUser();
    });
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    const curAddress = getAddress(user);
    const prevAddress = getAddress(prevProps.user);

    if (!_.isEqual(curAddress, prevAddress)) {
      this.setState({
        newAddress: curAddress?.address || '',
        newPhone: curAddress?.phone || ''
      });
    }
  }

  syncUserTier = () => {
    const { syncUserTier, user } = this.props;
    const tier = getUserTier(user);
    if (tier) syncUserTier(tier);
  };

  checkStripeStatus = () => {
    const { user, updateCurrentUser } = this.props;
    needsToCompleteStripeOnboarding(user) &&
      getStripeOnboardingStatus(user).then(resp => {
        if (resp.account?.payouts_enabled) {
          // This shouldn't happen, but if we get a response and are in fact updated, fire off the update
          updateCurrentUser({ stripeOnboardingComplete: true }, user.id);
        }
        this.setState({
          stripeAccountLinkWarnings: [
            ..._.get(resp, 'account.requirements.currently_due', []).map(formatStripeRequirement),
            ..._.get(resp, 'account.requirements.pending_verification', []).map(formatStripeRequirement)
          ]
        });
      });
  };

  requiresSave = () => {
    const { user } = this.props;
    const {
      name,
      email,
      oldPassword,
      newPassword,
      requestsEmail,
      username,
      image,
      amazonCode,
      skinbetterInviteCode,
      managerCompany,
      managerPosition
    } = this.state;
    return !!(
      (name && name.length && _.get(user, 'name') !== name) ||
      (email && email.length && _.get(user, 'email') !== email) ||
      (newPassword?.length && oldPassword.length && newPassword !== oldPassword) ||
      (requestsEmail && requestsEmail.length && _.get(user, 'requestsEmail') !== requestsEmail) ||
      (username && username.length && _.get(user, 'username') !== username) ||
      (image && image.length && _.get(user, 'image') !== image) ||
      (amazonCode && amazonCode.length && _.get(user, 'amazonCode') !== amazonCode) ||
      (managerCompany && managerCompany.length && _.get(user, 'managerCompany') !== managerCompany) ||
      (managerPosition && managerPosition.length && _.get(user, 'managerPosition') !== managerPosition) ||
      (skinbetterInviteCode && skinbetterInviteCode.length && _.get(user, 'skinbetterInviteCode') !== skinbetterInviteCode)
    );
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  updateUsername = username => {
    const formattedUsername = username.toLowerCase().replace(/[^A-Za-z0-9]/, '');
    this.setState({ username: formattedUsername });
  };

  validateUsername = async () => {
    const { username } = this.state;

    const validUsername = validateUsername(username);
    if (!validUsername) return;

    const usernameCheck = await doesUserExistAPI({ username });
    if (usernameCheck.exists) {
      cogoToast.error('Username already exists.');
      return false;
    }
    return true;
  };
  canSaveNewRate = () => {
    // For powering the UI to show the save button
    const { commission_rate, commission_rate_returning } = this.state;
    const brand = getBrand(this.props.user);
    if (commission_rate && commission_rate !== brand.commission_rate) return true;
    if ((commission_rate_returning ? +commission_rate_returning : 0) !== (brand.commission_rate_returning || 0)) return true;
    return false;
  };

  updateRate = () => {
    const { user, updateBrand } = this.props;
    const brand = getBrand(user);
    if (!brand) return;

    const isValid = rate => _.isNumber(rate) && rate >= 5 && rate <= 100;
    const sendInvalidRateError = () => window.ALERT.error('Please input a valid commission rate between 5% and 100%');

    // Standard Rate
    const newRate = parseFloat(this.state.commission_rate);
    const oldRate = _.get(getBrand(user), 'commission_rate');
    if (!isValid(newRate)) return sendInvalidRateError();

    // Returning Rate
    const newRateReturning = parseFloat(this.state.commission_rate_returning);
    const oldRateReturning = _.get(getBrand(user), 'commission_rate_returning');
    if (newRateReturning && !isValid(newRateReturning)) return sendInvalidRateError();

    // Must have a valid standard rate
    const isChangingRate = newRate !== oldRate || newRateReturning !== oldRateReturning;
    if (!isChangingRate) return;

    this.setState({ updatingRate: true });
    updateBrand(brand, { commission_rate: newRate || null, commission_rate_returning: newRateReturning || null })
      .then(() => {
        window.ALERT.success(`Successfully updated commission rate`);
      })
      .finally(() => {
        this.setState({ updatingRate: false, commission_rate: newRate, showingReturningCommissionRateInput: !!newRateReturning });
      });
  };

  updatePassword = async () => {
    const { oldPassword, newPassword } = this.state;

    const currentUser = await Auth.currentAuthenticatedUser();
    try {
      await Auth.changePassword(currentUser, oldPassword, newPassword);
      this.setState({ oldPassword: '', newPassword: '' });
    } catch (err) {
      throw _.get(err, 'message') || 'Incorrect password.';
    }
  };

  updateAddress = async () => {
    const { user } = this.props;
    const { newAddress, newPhone } = this.state;

    if (newPhone && !isValidPhoneNumber(newPhone, 'US')) return cogoToast.error('Please input a valid phone number.');

    if (this.state.updatingAddress) return;
    this.setState({ updatingAddress: true });

    this.props
      .setAddress(user, { address: newAddress, phone: newPhone })
      .then(resp => {
        const { error } = resp || {};

        if (error) {
          cogoToast.error(error);
        }
      })
      .finally(() => {
        this.setState({ updatingAddress: false });
      });
  };

  updateUtmParamsField = (field, value) => {
    this.setState({
      utmParams: {
        ...this.state.utmParams,
        [field]: value
      }
    });
  };

  updateUtmParams = async () => {
    if (this.state.updatingUtmParams) return;
    this.setState({ updatingUtmParams: true });
    this.props
      .updateBrandSettings({ utmParams: this.state.utmParams })
      .then(
        resp => cogoToast.success('Successfully updated.'),
        err => cogoToast.error(_.get(err, 'message') || 'Invalid Update.')
      )
      .finally(() => this.setState({ updatingUtmParams: false }));
  };

  updateReferringBrand = newBrandId => {
    const { user, updateCurrentUser } = this.props;
    if (this.state.isSavingProfile) return;
    const referringBrand = getReferringBrand(user);
    confirmAlert({
      title: 'Just confirming',
      message: `By removing this connection to ${referringBrand.name}, you will now be able to see and promote any product in the catalog and your shop will no longer be branded with the ${referringBrand.name} theme.`,
      buttons: [
        { label: 'No', onClick: () => {}, className: 'cancel' },
        {
          label: 'Yes',
          onClick: () => {
            updateCurrentUser({ ReferringBrand_id: newBrandId || null }, user.id)
              .then(
                resp => {
                  if (resp.error) return cogoToast.error('There was an error, please try again.');
                  cogoToast.success(newBrandId ? 'Successfully updated your profile.' : `Save successful - you are now a general user!`);
                  setTimeout(() => window.location.reload(), 500);
                  addEvent(`Settings - Removed Referring Brand`, { Brand: referringBrand.name, user });
                },
                err => {
                  cogoToast.error('There was an error updating your profile, please reload and try again.');
                }
              )
              .finally(() => {
                this.setState({ isSavingProfile: false });
              });
          }
        }
      ]
    });
  };

  handleSubmit = async e => {
    const { user, updateCurrentUser } = this.props;
    const {
      name,
      email,
      requestsEmail,
      username,
      bio,
      link,
      image,
      managerCompany,
      managerPosition,
      isSavingProfile,
      amazonCode,
      skinbetterInviteCode
    } = this.state;
    e.preventDefault();

    if (isSavingProfile) return;
    else if (!email) return cogoToast.error('Email Field cannot be left blank.');
    else if (!isValidEmail(email)) return cogoToast.error('Please input a valid email address.');

    const cleanedRequestEmail = (requestsEmail || '').split(' ').join('');
    const requestsEmails = cleanedRequestEmail ? cleanedRequestEmail.split(',') : [];
    const invalidEmail = _.find(requestsEmails, e => !isValidEmail(e));

    if (_.find(requestsEmails, e => !isValidEmail(e)))
      return cogoToast.error( `"${invalidEmail}" is not a valid email address, please enter a comma separated list of valid emails for requests to be sent to.`); // prettier-ignore

    // Perform Updates to User Object
    const userUpdates = {
      id: user.id,
      email,
      requestsEmail: cleanedRequestEmail,
      name,
      username,
      managerCompany,
      managerPosition,
      bio,
      link,
      image,
      amazonCode,
      skinbetterInviteCode
    };

    const runUpdate = async () => {
      const user_needs_password_update = this.state.newPassword && this.state.oldPassword !== this.state.newPassword;
      try {
        if (user_needs_password_update) await this.updatePassword();
      } catch (e) {
        return cogoToast.error(e);
      }

      try {
        const update_response = await updateCurrentUser(userUpdates, user.id);
        if (update_response.error) cogoToast.error('There was an error updating your profile, please check your data and try again.');
        else cogoToast.success('Successfully updated your profile.');
      } catch (e) {
        cogoToast.error('There was an error updating your profile, please reload and try again.');
      }

      this.setState({ isSavingProfile: false, isEditingUserDataPanel: false });
    };

    const username_needs_update_but_isnt_valid = user.username !== username && !(await this.validateUsername());
    if (username_needs_update_but_isnt_valid) return;

    this.setState({ isSavingProfile: true });
    runUpdate();
  };

  connectInstagram = () => {
    openInstagramAuthModal(
      async authResponse => {
        this.setState({ isAddingSocialAccountType: 'instagram' });
        this.props
          .connectInstagram(authResponse)
          .then(resp => {})
          .catch(errorMessage => {
            // This is for debugging
            console.error({ errorMessage });
            cogoToast.error(errorMessage);
          })
          .finally(() => this.setState({ isAddingSocialAccountType: null }));
      },
      failure => {
        this.props.openConnectInstagramModal();
      }
    );
  };

  connectStripe = async () => {
    if (this.state.isConnectingStripe) return cogoToast.warn(`Already in the process of connecting.`);
    this.setState({ isConnectingStripe: true });
    this.props
      .connectStripeAccount()
      .then(resp => goToStripeOnboarding(this.props.user))
      .finally(() => this.setState({ isConnectingStripe: false }));
  };

  saveShopifyIntegration = async () => {
    const brand = getBrand(this.props.user);
    const shopName = this.state.shopifyShopName;
    const privateKey = this.state.shopifyPrivateKey;
    const adminAccessToken = this.state.shopifyAdminAccessToken;
    const data = { shopName, privateKey, adminAccessToken };

    if (!shopName || !privateKey || !adminAccessToken) return cogoToast.error('Please fill out all fields.');
    else if (!this.state.shopifyTestSuccess) return cogoToast.error('Please test your Shopify connection before saving.');

    this.setState({ isSavingShopify: true });
    this.props
      .saveShopifyIntegration(brand, data)
      .then(() => cogoToast.success('Successfully saved Shopify config.'))
      .catch(() => cogoToast.error('There was an error saving your Shopify config.').finally(() => this.setState({ isSavingShopify: false })));
  };

  testShopifyIntegration = async e => {
    // don't seed any of these values with values from props because backend function will use the database values if null
    const Brand_id = getBrand(this.props.user).id;
    const shopName = this.state.shopifyShopName;
    const privateKey = this.state.shopifyPrivateKey;
    const adminAccessToken = this.state.shopifyAdminAccessToken;

    if (!shopName || !privateKey || !adminAccessToken) return cogoToast.error('Please fill out all fields.');
    const data = { Brand_id, shopName, privateKey, adminAccessToken };

    this.setState({ isTestingShopify: true });
    testShopifyIntegrationAPI(data)
      .then(() => {
        cogoToast.success('Successful! Please press save.');
        this.setState({ isTestingShopify: false, shopifyTestSuccess: true });
      })
      .catch(e => {
        cogoToast.error(e || 'There was an error connecting to Shopify, please check your credentials and try again.');
        this.setState({ isTestingShopify: false });
      });
  };

  syncShopifyIntegrationScopes = async () => {
    const brand = getBrand(this.props.user);
    brand && (await this.props.syncShopifyScopesForBrandId(brand.id));
  };

  disconnectSocialAccount = type => {
    const account = getSocialAccount(this.props.user, type);
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to disconnect this account? This will remove all reporting on your brand collaborations.`,
      buttons: [
        { label: 'No', onClick: () => {}, className: 'cancel' },
        {
          label: 'Yes',
          onClick: () => this.props.disconnectSocialAccount(account)
        }
      ]
    });
  };

  getTopMerchantForBrand = () => {
    const brand = getBrand(this.props.user);
    const { merchants } = brand || {};
    return _.find(merchants, m => m.isSMSWinner);
  };

  getBrandCommissionRate = () => {
    const ownCommissionRate = _.get(getBrand(this.props.user), 'commission_rate');
    const topCommissionRate = _.get(this.getTopMerchantForBrand(), 'fullPayout');
    return ownCommissionRate || topCommissionRate;
  };

  getBrandCommissionRateReturning = () => {
    const brand = getBrand(this.props.user);
    const topMerchantForBrand = this.getTopMerchantForBrand();
    return topMerchantForBrand?.source === 'shopmyshelf' ? brand?.commission_rate_returning : null;
  };

  getVisibleSMSRate = () => ((parseFloat(this.state.commission_rate) || 0) * 0.82).toFixed(1);

  toggleUserSetting = async (setting, value) => {
    await this.props.updateUserSettings({ [setting]: value });
    this.setState({ [setting]: value });
  };

  handleRotateDeveloperKey = () => {
    confirmAlert({
      title: 'Are you sure you want to rotate your developer key?',
      message: (
        <>
          Your current developer key will be replaced and all ShopMy API requests must be made with the new key.
          <br />
          <b>This cannot be undone.</b>
        </>
      ),
      buttons: [
        {
          label: 'Rotate developer key',
          className: 'danger',
          onClick: async () => {
            const res = await this.props.rotateBrandDeveloperKey(getBrand(this.props.user)?.id);

            if (res.error) {
              return cogoToast.error('There was an error rotating your developer key, please try again.');
            }
            cogoToast.success('Rotated ShopMy developer key!');
          }
        },
        { label: 'Cancel', onClick: () => {}, className: 'cancel' }
      ]
    });
  };

  render() {
    const { user, manager, isSimulatingUser } = this.props;
    const {
      name,
      username,
      email,
      requestsEmail,
      amazonCode,
      skinbetterInviteCode,
      utmParams,
      isKeyVisible,
      stripeAccountLinkWarnings,
      managerCompany,
      managerPosition,
      commission_rate,
      commission_rate_returning,
      isAddingSocialAccountType,
      isSavingProfile,
      allowLocationServices
    } = this.state;
    const developerKey = _.get(user, 'developerKey') || this.props.developerKey || null;
    const brand = getBrand(user);
    const instaAccount = getSocialAccount(user, 'instagram');
    const youtubeAccount = getSocialAccount(user, 'youtube');
    const tiktokAccount = getSocialAccount(user, 'tiktok');
    const referringBrand = getReferringBrand(user);
    const address = getAddress(user);
    const isChangingEmail = (email || '') !== (getEmail(user) || '');
    const isChangingAddress = (this.state.newAddress || '') !== (address?.address || '');
    const isChangingPhone = (this.state.newPhone || '') !== (address?.phone || '');
    const isPhoneInvalid = isChangingPhone && this.state.newPhone && !isValidPhoneNumber(this.state.newPhone, 'US');
    const showReturningInput = this.state.showingReturningCommissionRateInput || commission_rate_returning;
    const cannotSetReturningRate = !doAllShopifyIntegrationsHavePermission(user, 'read_customers');
    const requiresSave = this.requiresSave();
    return (
      <RequiresPermissions permission='canEditSettings'>
        <div className='new-settings-profile-wrapper'>
          <FacebookSDK />
          <Prompt when={false} message='You have unsaved changes, are you sure you want to leave?' />
          <form className='settings-form' onSubmit={this.handleSubmit}>
            {!isManager(user) && !window.__IS_APP__ && (
              <div className='action-btns-container'>
                <div className='btn cancel' onClick={() => this.setState({ isEditingUserDataPanel: false })}>
                  Cancel
                </div>
                <div className={classnames('btn', { 'needs-press': requiresSave })} onClick={this.handleSubmit}>
                  {isSavingProfile ? 'Saving...' : 'Save'}
                </div>
              </div>
            )}
            {isBrand(user) ? (
              <div className='settings-display-card'>
                <div className='settings-block'>
                  <div className='label'>Primary Email (Used for Login)</div>
                  <input
                    disabled={isSimulatingUser}
                    placeholder='Email Address'
                    type='email'
                    onChange={({ target }) => this.setState({ email: target.value })}
                    value={email}
                  />
                  {isChangingEmail && (
                    <div className='update-btn' onClick={this.handleSubmit}>
                      {isSavingProfile ? 'Updating...' : 'Update Email'}
                    </div>
                  )}
                </div>
                <div className='settings-block'>
                  <div className='label'>Update Password</div>
                  <input
                    placeholder='Old Password'
                    type='password'
                    onChange={({ target }) => this.setState({ oldPassword: target.value })}
                    value={this.state.oldPassword}
                  />
                  <input
                    placeholder='New Password'
                    type='password'
                    onChange={({ target }) => this.setState({ newPassword: target.value })}
                    value={this.state.newPassword}
                  />
                </div>
              </div>
            ) : (
              <div className='settings-display-card user-data-panel'>
                <div className='image-container'>
                  <div className='initials'>{getInitialsForUser(user)}</div>
                </div>
                {this.state.isEditingUserDataPanel ? (
                  <div className='user-data-main edit'>
                    <div className='data-block'>
                      <div className='label'>{isManager(user) ? 'Title' : 'Full Name'}</div>
                      <input placeholder='Name' type='name' onChange={({ target }) => this.setState({ name: target.value })} value={name} />
                    </div>
                    <div className='data-block'>
                      <div className='label'>Username</div>
                      <input placeholder='Username' type='text' onChange={({ target }) => this.updateUsername(target.value)} value={username} />
                    </div>
                    <div className='data-block'>
                      <div className='label'>Email</div>
                      <input
                        placeholder='Email Address'
                        type='email'
                        disabled={isCurrentlyManaging(manager) || isSimulatingUser}
                        onChange={({ target }) => this.setState({ email: target.value })}
                        value={email}
                      />
                    </div>
                    <div className='data-block'>
                      <div className='label'>Update Password</div>
                      <input
                        placeholder='Old Password'
                        type='password'
                        disabled={isCurrentlyManaging(manager)}
                        onChange={({ target }) => this.setState({ oldPassword: target.value })}
                        value={this.state.oldPassword}
                      />
                      <input
                        placeholder='New Password'
                        type='password'
                        disabled={isCurrentlyManaging(manager)}
                        onChange={({ target }) => this.setState({ newPassword: target.value })}
                        value={this.state.newPassword}
                      />
                    </div>
                    {isManager(user) && (
                      <>
                        <div className='data-block'>
                          <div className='label'>Company</div>
                          <input
                            placeholder='Company Name'
                            onChange={({ target }) => this.setState({ managerCompany: target.value })}
                            value={managerCompany}
                          />
                        </div>
                        <div className='data-block'>
                          <div className='label'>Position</div>
                          <input
                            placeholder='Manager, Director, etc.'
                            onChange={({ target }) => this.setState({ managerPosition: target.value })}
                            value={managerPosition}
                          />
                        </div>
                      </>
                    )}

                    <div className='action-btns'>
                      <div
                        className={classnames('action-btn cancel', {
                          'needs-press': requiresSave
                        })}
                        onClick={() => this.setState({ isEditingUserDataPanel: false })}
                      >
                        Cancel
                      </div>
                      <div
                        className={classnames('action-btn save', {
                          'needs-press': requiresSave
                        })}
                        onClick={this.handleSubmit}
                      >
                        Save
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='user-data-main display'>
                    <div className='name'>{name}</div>
                    <div className='username'>
                      {_.filter(
                        isManager(user) && (!!managerCompany || !!managerPosition) ? [managerCompany, managerPosition] : ['@' + username]
                      ).join(' • ')}
                    </div>
                    <div className='data-block'>
                      <div className='label'>Email</div>
                      <div className='sublabel'>{email}</div>
                    </div>
                    <div className='data-block'>
                      <div className='label'>Password</div>
                      <div className='sublabel'>••••••••••</div>
                    </div>
                    <div className='action-btns'>
                      <div className='action-btn edit' onClick={() => this.setState({ isEditingUserDataPanel: true })}>
                        Edit
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {isTalent(user) && !window.__IS_APP__ && (
              <div className='settings-display-card'>
                <UserTierPanel user={user} />
              </div>
            )}
            {isTalent(user) && !window.__IS_APP__ && (
              <div className='settings-display-card'>
                <div className='settings-block'>
                  <div className='label'>Connect Your Social Accounts</div>
                  <div className='sublabel'>
                    Connect your social accounts for more in-depth analytics on your brand collaborations. For more information or help getting set
                    up, see our general guide{' '}
                    <a href='https://guide.shopmy.us/how-to-link-your-social-media' target='_blank' rel='noopener noreferrer'>
                      here
                    </a>{' '}
                    or Instagram specific guide{' '}
                    <span onClick={this.props.openConnectInstagramModal} className='link'>
                      here
                    </span>
                    .
                  </div>
                  <div className='connect-social-btns'>
                    {instaAccount ? (
                      <div onClick={() => this.disconnectSocialAccount('instagram')} className='connect-social-btn instagram disconnect'>
                        <img className='social-icn' src={icons.social.instagram.dark} alt='Instagram Logo' />
                        Instagram Connected
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    ) : (
                      <div onClick={this.connectInstagram} className='connect-social-btn instagram'>
                        <img className='social-icn' src={icons.social.instagram.dark} alt='Instagram Logo' />
                        {isAddingSocialAccountType === 'instagram' ? 'Connecting...' : 'Connect Instagram'}
                      </div>
                    )}
                    {tiktokAccount ? (
                      <div onClick={() => this.disconnectSocialAccount('tiktok')} className='connect-social-btn tiktok disconnect'>
                        <img className='social-icn' src={icons.social.tiktok.dark} alt='TikTok Logo' />
                        TikTok Connected
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    ) : (
                      <div onClick={openTikTokAuthModal} className='connect-social-btn tiktok'>
                        <img className='social-icn' src={icons.social.tiktok.dark} alt='TikTok Logo' />
                        Connect TikTok
                      </div>
                    )}
                    {youtubeAccount ? (
                      <div onClick={() => this.disconnectSocialAccount('youtube')} className='connect-social-btn youtube disconnect'>
                        <img className='social-icn youtube' src={icons.social.youtube.dark} alt='YouTube Logo' />
                        YouTube Connected
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    ) : (
                      <div onClick={openYoutubeAuthModal} className='connect-social-btn youtube'>
                        <img className='social-icn youtube' src={icons.social.youtube.dark} alt='YouTube Logo' />
                        Connect YouTube
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {isTalent(user) && (
              <div className='settings-display-card'>
                <div className='settings-block'>
                  <div className='label'>Address For Gifting</div>
                  <div className='sublabel'>This is the address we will share with brands when you agree to receiving gifting.</div>
                  <div className='address' onClick={this.props.openAddressModal}>
                    {!!address?.address ? address.address : <div style={{ color: '#666' }}>Add your address to receive gifts from brands</div>}
                  </div>
                  <input
                    className={cn('phone-input', { invalid: isPhoneInvalid })}
                    placeholder='Phone number (optional)'
                    type='address'
                    onChange={({ target }) => this.setState({ newPhone: target.value })}
                    value={this.state.newPhone}
                  />
                  {(isChangingAddress || isChangingPhone) && (
                    <div onClick={this.updateAddress} className='update-btn'>
                      {this.state.updatingAddress ? 'Updating...' : isChangingAddress ? 'Update Address' : 'Update Phone'}
                    </div>
                  )}
                </div>
              </div>
            )}
            {(isTalent(user) || isManager(user)) && (
              <div className='settings-display-card'>
                <div className='settings-block'>
                  <div className='label'>Connect Stripe</div>
                  <div className='sublabel'>
                    {isManager(user)
                      ? 'Connect your Stripe account to receive payments for your cut of creator opportunities. If you are outside the US, please connect your PayPal account instead on the Earnings tab.'
                      : `Stripe is currently only available for US-based creators. If you're a creator outside of the US, please connect your PayPal on the Earnings tab.`}
                  </div>
                  <div className='connect-payments-btns'>
                    {getStripeAccountId(user) ? (
                      <>
                        <div
                          onClick={() => goToStripeOnboarding(user)}
                          className={cn('connect-payments-btn stripe link', { warning: needsToCompleteStripeOnboarding(user) })}
                        >
                          <img className='icn' src={stripeIcon} alt='Stripe Logo' />
                          {needsToCompleteStripeOnboarding(user) ? (
                            <>
                              <div className='data'>
                                {!!stripeAccountLinkWarnings.length ? (
                                  <>
                                    Stripe Needs More Information
                                    <div className='warnings'>
                                      {stripeAccountLinkWarnings.slice(0, 3).map((warning, idx) => (
                                        <div key={idx} className='warning'>
                                          <FontAwesomeIcon icon={faExclamationTriangle} />
                                          {warning}
                                        </div>
                                      ))}
                                      {stripeAccountLinkWarnings.length > 3 ? (
                                        <div className='warning'>and {stripeAccountLinkWarnings.length - 3} more...</div>
                                      ) : (
                                        <div className='warning detailed'>
                                          <FontAwesomeIcon icon={faInfoCircle} />
                                          You may need to wait a few minutes for your last Stripe action to process
                                        </div>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    Stripe Is Reviewing
                                    <div className='warnings'>
                                      <div className='warning detailed'>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                        You should receive an email when everything has been verified
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <FontAwesomeIcon icon={faExternalLink} />
                            </>
                          ) : (
                            <>
                              Stripe Connected
                              <FontAwesomeIcon icon={faExternalLink} />
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <div onClick={this.connectStripe} className='connect-payments-btn stripe'>
                        <img className='social-icn' src={stripeIcon} alt='Stripe Logo' />
                        {this.state.isConnectingStripe ? 'Connecting...' : 'Connect Stripe'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {isBrand(user) && (
              <div className='settings-display-container'>
                <div className='settings-display-card-title'>Manage Shopify Integration</div>
                <div className='settings-display-card'>
                  <ShopifyIntegrationPanel />
                </div>
              </div>
            )}
            {!isBrand(user) && (
              <div className='settings-display-card'>
                <UserManagersPanel />
              </div>
            )}
            {!isBrand(user) && !!getTokens(user)?.length && (
              <div className='settings-display-card'>
                <UserTokensPanel user={user} />
              </div>
            )}
            {!isBrand(user) && !isManager(user) && (
              <div className='settings-display-card'>
                <UserTagsPanel user={user} addTagsToUser={this.props.addTagsToUser} deleteTagsForUser={this.props.deleteTagsForUser} />
              </div>
            )}
            {isTalent(user) && (
              <div className='settings-display-card'>
                <CodeSettingsPanel user={user} updateUserSettings={this.props.updateUserSettings} />
              </div>
            )}
            {isTalent(user) && (
              <div className='settings-display-card'>
                <div className='settings-block'>
                  <div className='label'>Amazon Affiliate Code</div>
                  <div className='sublabel'>
                    If you would like to use a custom Amazon affiliate code, please enter it below and we will automatically convert standard Amazon
                    links into affiliate links. Please make sure to add shopmy.us as a valid domain in your Amazon affiliate settings.
                  </div>
                  <input
                    placeholder='Example: my-code-20'
                    type='text'
                    name='amazon-code'
                    onChange={({ target }) => {
                      if (target.value.includes('https://'))
                        cogoToast.error('Only make sure to paste in the code in the format my-code-20, not the whole URL');
                      this.setState({ amazonCode: target.value });
                    }}
                    value={amazonCode}
                  />
                </div>
                <div className='settings-block'>
                  <div className='label'>Skinbetter Invitation Code</div>
                  <div className='sublabel'>If you do not know your invitation code please reach out to your SkinBetter contact directly.</div>
                  <input
                    placeholder='Example: 209176'
                    type='text'
                    name='skinbetter-code'
                    onChange={({ target }) => this.setState({ skinbetterInviteCode: target.value })}
                    value={skinbetterInviteCode}
                  />
                </div>
              </div>
            )}
            {isTalent(user) && (
              <div className='settings-display-card'>
                <div className='settings-block'>
                  <div className='label'>Location Data</div>
                  <div className='sublabel'>
                    We may use your location data to help brands connect with you for collaborations. Details on how we use your location data can be
                    found{' '}
                    <a target='_blank' rel='noopener noreferrer' href={`https://static.shopmy.us/Privacy_Policy.pdf`}>
                      here
                    </a>{' '}
                    in our privacy policy. You have the option to opt out of location data collection anytime via the checkbox below.
                  </div>
                  <div className='checkboxes'>
                    <div
                      onClick={() => {
                        this.toggleUserSetting('allowLocationServices', !allowLocationServices);
                      }}
                      className={cn('checkbox-container', { selected: allowLocationServices })}
                    >
                      <div className='checkbox' />
                      <div className='checkbox-text'>
                        <div className='checkbox-label'>Allow Location Data</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isBrand(user) && !isShopifyUserRequiredForAppAcceptance(user) && (
              <div className='settings-display-card for-brands'>
                {isSubscribedToFeature(user, 'GIFTING') && (
                  <div className='settings-block'>
                    <div className='label'>Email For Request Responses</div>
                    <div className='sublabel'>
                      If you would like to add a different email address (instead of your login email) to receive a confirmation email when talent
                      accept your gifting requests, please input it here. If you would like the confirmation sent to multiple emails, please separate
                      them by commas.
                    </div>
                    <input
                      placeholder='Email Address For Requests'
                      type='text'
                      onChange={({ target }) => this.setState({ requestsEmail: target.value })}
                      value={requestsEmail || ''}
                    />
                    {requestsEmail && (
                      <div onClick={this.handleSubmit} className='update-btn'>
                        Update Email
                      </div>
                    )}
                  </div>
                )}
                <div className='settings-block'>
                  <div className='label'>Update Commission Rate</div>
                  <div className='sublabel'>
                    {!doesTakePercentageFromUser(brand) ? (
                      isSMSAffiliatePartnerBrand(user) ? (
                        <>
                          This is the commission rate that the creator will see when they add your products.
                          {!this.state.showingReturningCommissionRateInput ? (
                            <>
                              {' '}
                              To set a separate rate for returning customers, click{' '}
                              <span
                                onClick={() =>
                                  this.setState({ showingReturningCommissionRateInput: !this.state.showingReturningCommissionRateInput })
                                }
                                className='link'
                              >
                                here.
                              </span>
                            </>
                          ) : (
                            ' The rate for returning customers will be explicitly shown to creators as a disclaimer.'
                          )}
                        </>
                      ) : (
                        <>
                          This commission rate represents the percentage of the final purchase that we will charge in the invoice. We operate on a
                          revenue share model with our talent, with an 82/18 split. This means at the current rate of <b>{commission_rate}%</b> a
                          ShopMy creator will see <b>{this.getVisibleSMSRate()}%</b>.
                        </>
                      )
                    ) : !isSMSAffiliatePartnerBrand(user) ? (
                      this.getTopMerchantForBrand() ? (
                        <>
                          This is the current commission rate we are offering users via {getDisplaySourceFromMerchant(this.getTopMerchantForBrand())}.
                          We operate on a revenue share model with our creators with an 82/18 split, so at the current rate of{' '}
                          <b>{commission_rate}%</b> a ShopMy creator will see <b>{this.getVisibleSMSRate()}%</b>.
                        </>
                      ) : (
                        <>
                          We cannot currently offer a commission rate to our artists until you complete integration with our direct affiliate network
                          using the instructions <Link to='brand-setup'>here</Link>.
                        </>
                      )
                    ) : (
                      <>
                        This commission rate represents the percentage of the final purchase that we will charge in the invoice. We operate on a
                        revenue share model with our artists, with an 82/18 split for ShopMy creators. This means at the current rate of{' '}
                        <b>{commission_rate}%</b> a ShopMy creator will see <b>{this.getVisibleSMSRate()}%</b>.
                      </>
                    )}
                  </div>
                  {showReturningInput && <div className='input-label'>Commission Rate for New Customers</div>}
                  <input
                    placeholder='Commission Rate'
                    className={cn({ disabled: !isSMSAffiliatePartnerBrand(user) })}
                    onChange={({ target }) => isSMSAffiliatePartnerBrand(user) && this.setState({ commission_rate: target.value })}
                    value={commission_rate || ''}
                  />
                  {showReturningInput && (
                    <>
                      <div onClick={this.syncShopifyIntegrationScopes} className={cn('input-label', { clickable: cannotSetReturningRate })}>
                        Commission Rate for Returning Customers
                        {cannotSetReturningRate ? (
                          <span className='warning'>
                            {' '}
                            • You must enable the read_customers scope on all of your Shopify integrations, please click here once you have done so.
                            If you are having trouble, please reach out to your account manager for support!
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <Tooltip message={cannotSetReturningRate ? `You must first enable the read_customer scope prior to setting this rate.` : ''}>
                        <input
                          placeholder={commission_rate ? `${commission_rate}%` : 'Commission Rate for Returning Customers'}
                          className={cn({ disabled: !isSMSAffiliatePartnerBrand(user) })}
                          disabled={cannotSetReturningRate}
                          onChange={({ target }) => isSMSAffiliatePartnerBrand(user) && this.setState({ commission_rate_returning: target.value })}
                          value={commission_rate_returning || ''}
                        />
                      </Tooltip>
                    </>
                  )}
                  {isSMSAffiliatePartnerBrand(user) && this.canSaveNewRate() && (
                    <div onClick={this.updateRate} className='update-btn'>
                      {this.state.updatingRate ? 'Updating...' : 'Update Rate'}
                    </div>
                  )}
                </div>
              </div>
            )}
            {isBrand(user) && isSMSAffiliatePartnerBrand(user) && getBrandSettings(user) && (
              <div className='settings-display-card for-brands'>
                {[
                  {
                    display: 'UTM Source',
                    variable: 'utm_source',
                    defaultValue: 'ShopMy'
                  },
                  {
                    display: 'UTM Medium',
                    variable: 'utm_medium',
                    defaultValue: 'affiliate'
                  },
                  {
                    display: 'UTM Campaign',
                    variable: 'utm_campaign',
                    defaultValue: 'USER_NAME',
                    canSelectFromEnum: true
                  },
                  {
                    display: 'UTM Content',
                    variable: 'utm_content',
                    defaultValue: 'CONTENT_NAME',
                    canSelectFromEnum: true
                  },
                  {
                    display: 'UTM Referrer',
                    variable: 'utm_referrer',
                    defaultValue: 'CONTENT_REFERRER',
                    fixedResultDisplay: 'Domain the click was generated from, ex: shopmy.us, instagram.com, substack.com, etc.'
                  }
                ].map(({ display, variable, defaultValue, canSelectFromEnum, fixedResultDisplay }) => {
                  const value = utmParams[variable];
                  const isUserName = value === 'USER_NAME' || (!value && defaultValue === 'USER_NAME');
                  const isUserUsername = value === 'USER_USERNAME' || (!value && defaultValue === 'USER_USERNAME');
                  const isContent = value === 'CONTENT_NAME' || (!value && defaultValue === 'CONTENT_NAME');
                  const isCustom = !isUserName && !isUserUsername && !isContent;
                  const inputField = (
                    <input
                      placeholder={defaultValue}
                      type='text'
                      onChange={({ target }) => this.updateUtmParamsField(variable, target.value)}
                      value={utmParams[variable] || ''}
                    />
                  );
                  return (
                    <div key={variable} className='settings-block'>
                      <div className='label'>{display}</div>
                      {fixedResultDisplay ? (
                        <div className='select-utm-param-options'>
                          <div className='select-utm-param-option selected'>
                            <div className='checkbox' />
                            <div className='checkbox-label'>{fixedResultDisplay}</div>
                          </div>
                        </div>
                      ) : canSelectFromEnum ? (
                        <div className='select-utm-param-options'>
                          <div
                            onClick={() => this.updateUtmParamsField(variable, 'USER_NAME')}
                            className={cn('select-utm-param-option', { selected: isUserName })}
                          >
                            <div className='checkbox' />
                            <div className='checkbox-label'>Name of the Talent, ex: Katie Jane Hughes</div>
                          </div>
                          <div
                            onClick={() => this.updateUtmParamsField(variable, 'USER_USERNAME')}
                            className={cn('select-utm-param-option', { selected: isUserUsername })}
                          >
                            <div className='checkbox' />
                            <div className='checkbox-label'>Username of the Talent, ex: katiejanehughes</div>
                          </div>
                          <div
                            onClick={() => this.updateUtmParamsField(variable, 'CONTENT_NAME')}
                            className={cn('select-utm-param-option', { selected: isContent })}
                          >
                            <div className='checkbox' />
                            <div className='checkbox-label'>Name of the Content, ex: My Favorite Products of {moment().format('YYYY')}</div>
                          </div>
                          <div
                            onClick={() => this.updateUtmParamsField(variable, 'Custom Value')}
                            className={cn('select-utm-param-option', { selected: isCustom })}
                          >
                            <div className='checkbox' />
                            <div className='checkbox-label'>Custom</div>
                          </div>
                          {isCustom && inputField}
                        </div>
                      ) : (
                        inputField
                      )}
                    </div>
                  );
                })}
                {!_.isEqual(getBrandUtmParams(user), utmParams) && (
                  <div onClick={this.updateUtmParams} className='update-btn'>
                    {this.state.updatingUtmParams ? 'Updating...' : 'Update UTM Parameters'}
                  </div>
                )}
              </div>
            )}
            {isBrand(user) && (
              <div className='settings-display-card for-brands'>
                <CompetitorBrandSettingsPanel />
              </div>
            )}
            {isBrand(user) && (
              <div className='settings-display-card'>
                <StripeConfigurationPanel />
              </div>
            )}
            {isBrand(user) && (
              <div className='settings-display-card for-brands'>
                <div className='settings-block'>
                  <div className='label'>Developer Key</div>
                  <div className='sublabel'>
                    Key for developer access to the ShopMy API. Keep this secure, do not share in publicly accessible areas.
                  </div>
                  {!!developerKey ? (
                    <>
                      <input
                        className={cn('field-input', { 'privacy-mode': !isKeyVisible })}
                        placeholder='Developer Key'
                        type='text'
                        value={isKeyVisible ? developerKey : '•'.repeat(developerKey.length)}
                        readOnly={true}
                      />
                      <div className='buttons space-between'>
                        <div className={'btn'} onClick={() => this.setState({ isKeyVisible: !isKeyVisible })}>
                          {isKeyVisible ? 'hide' : 'show'}
                        </div>
                        <div className={'btn danger'} onClick={this.handleRotateDeveloperKey}>
                          Rotate Key
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={'settings-block-field-info'}>
                      Please request a developer key from your account manager if you would like secure developer access to the ShopMy API.
                    </div>
                  )}
                </div>
              </div>
            )}
            {isBrand(user) && (
              <div className='settings-display-card'>
                <BlacklistedCodesPanel />
              </div>
            )}
            {!!referringBrand && (
              <div className='settings-display-card'>
                <div className='settings-block'>
                  <div className='label'>Promotion Settings</div>
                  <div className='sublabel'>
                    You are currently registered as a {referringBrand.name} promoter. If you would like to disconnect from {referringBrand.name}, you
                    can do so below.
                  </div>
                  <div onClick={() => this.updateReferringBrand(null)} className='remove-referring-brand-link-btn'>
                    Remove {referringBrand.name} Link
                  </div>
                </div>
              </div>
            )}
            {!isManager(user) && (
              <div className='action-btns-container footer'>
                <div className='btn cancel' onClick={() => this.props.history.push('/settings')}>
                  Cancel
                </div>
                <div
                  className={classnames('btn', {
                    'needs-press': requiresSave
                  })}
                  onClick={this.handleSubmit}
                >
                  Save
                </div>
              </div>
            )}
          </form>
        </div>
      </RequiresPermissions>
    );
  }
}

export default withRouter(SettingsProfile);
