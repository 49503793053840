import React from 'react';
import './BrandSetupRedirect.scss';

const BrandSetupRedirect = () => {
  return (
    <div className='brand-setup-redirect-outer'>
      <div className='brand-setup-redirect-inner'>
        <div className='brand-setup-redirect-title'>
          <h1>Our Docs Have Moved</h1>
        </div>
        <div className='brand-setup-redirect-subtitle'>
          We built a more robust documentation site to help you get started with our partner requirements. You can now find all of our documentation
          at{' '}
          <a href='https://docs.shopmy.us/reference/getting-started-1' target='_blank' rel='noreferrer'>
            docs.shopmy.us
          </a>
          . Please update your bookmarks and links accordingly.
        </div>
      </div>
    </div>
  );
};

export default BrandSetupRedirect;
