import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import './EarningsPortalHeader.scss';

import { getUserTiers } from '../../Helpers/tier_helpers';
import { getUserTier } from '../../Helpers/user_helpers';

const EarningsPortalHeader = props => {
  const { user, payoutsAreLoading } = props;
  const payoutSummaries = _.get(user, 'payouts.payout_summaries.months', {});

  const monthlyTotals = useMemo(() => {
    const transformedTotals = Object.entries(payoutSummaries).map(([date, payoutSummary]) => {
      const { user_payout_total, user_payout_total_locked, user_payout_total_paid } = payoutSummary;
      const lockedPayoutsAreIncomplete = user_payout_total !== user_payout_total_locked && user_payout_total_locked > 0;

      return {
        date,
        amount: user_payout_total,
        lockedAmount: user_payout_total_locked,
        lockedAndNotPaidAmount: user_payout_total_locked - user_payout_total_paid,
        paidAmount: user_payout_total_paid,
        lockedTooltip: lockedPayoutsAreIncomplete
          ? 'Some of this months payouts have not yet locked. Commissions can take up to 105 days to pass the retailercs return window and consult payouts must pass a seven day refund window.'
          : null
      };
    });

    const sortedTotals = _.orderBy(transformedTotals, ({ date }) => new Date(date), 'desc');
    return sortedTotals;
  }, [payoutSummaries]);

  const earnings = _.sum(_.map(monthlyTotals, 'amount'));
  const paidEarnings = _.sum(_.map(monthlyTotals, 'paidAmount'));
  const lockedEarnings = _.sum(_.map(monthlyTotals, payout => payout.lockedAmount - payout.paidAmount));
  const pendingEarnings = _.sum(_.map(monthlyTotals, payout => payout.amount - payout.lockedAndNotPaidAmount - payout.paidAmount));

  const allUserTiers = getUserTiers();
  const rawTier = getUserTier(user);
  const currentTier = allUserTiers.find(tier => tier.tier === rawTier?.tier) || allUserTiers[0];
  const nextTier = currentTier.tier === 1 ? null : allUserTiers.find(tier => tier.tier === currentTier.tier - 1);

  const currentTierDisplay = currentTier.label;
  const nextTierDisplay = nextTier ? nextTier.label : null;
  const pointsToNextTier = nextTier ? Math.floor(parseFloat(nextTier.minScore - rawTier.score)) : null;

  return (
    <div className='earnings-portal-header-outer'>
      <div className='earnings-portal-header-inner'>
        <div className='earnings-portal-text-container'>
          <div className='earnings-portal-text-container-header'>{currentTierDisplay}</div>
          {!!nextTier ? (
            <div className='earnings-portal-text-container-subheader'>
              <span>{pointsToNextTier} points</span> until {nextTierDisplay} tier
            </div>
          ) : (
            <div className='earnings-portal-text-container-subheader'>World class product advice</div>
          )}
        </div>

        <div className='earnings-portal-header-summary-container'>
          <div className='summary-cell'>
            {payoutsAreLoading ? (
              <div className='skeleton-number'></div>
            ) : (
              <div className='summary-number'>{lockedEarnings && lockedEarnings > 0 ? `$${commaNumber(lockedEarnings.toFixed(0))}` : '-'}</div>
            )}
            <div className='summary-label desktop-only'>Upcoming Payment</div>
            <div className='summary-label mobile-only'>Upcoming</div>
          </div>

          <div className='summary-cell'>
            {payoutsAreLoading ? (
              <div className='skeleton-number'></div>
            ) : (
              <div className='summary-number'>{pendingEarnings ? `$${commaNumber(pendingEarnings.toFixed(0))}` : '-'}</div>
            )}
            <div className='summary-label desktop-only'>Pending Payouts</div>
            <div className='summary-label mobile-only'>Pending</div>
          </div>

          <div className='summary-cell'>
            {payoutsAreLoading ? (
              <div className='skeleton-number'></div>
            ) : (
              <div className='summary-number'>{paidEarnings ? `$${commaNumber(paidEarnings.toFixed(0))}` : '-'}</div>
            )}
            <div className='summary-label desktop-only'>Amount Paid</div>
            <div className='summary-label mobile-only'>Paid</div>
          </div>

          <div className='summary-cell'>
            {payoutsAreLoading ? (
              <div className='skeleton-number'></div>
            ) : (
              <div className='summary-number'>{earnings ? `$${commaNumber(earnings.toFixed(0))}` : '-'}</div>
            )}
            <div className='summary-label desktop-only'>Lifetime Earnings</div>
            <div className='summary-label mobile-only'>Lifetime</div>
          </div>
        </div>
      </div>
    </div>
  );
};

EarningsPortalHeader.propTypes = {
  ui: PropTypes.object.isRequired,
  payoutsAreLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { user, blog, ui } = state;
  return { user, blog, ui };
};

export default connect(mapStateToProps)(EarningsPortalHeader);
