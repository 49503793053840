import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import icons from '../../static/icons';
import './SubscriptionOfferTile.scss';

import { ActionsOverlay } from '../Modals/ActionsOverlay';

import { openSubscriptionOfferModal } from '../../Actions/UIActions';
import { subscribeToOffer, cancelSubscriptionItem, updateSubscriptionItem } from '../../Actions/SubscriptionActions';

import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getBrandSubscription } from '../../Helpers/user_helpers';

const SubscriptionOfferTile = props => {
  const { user, ui, offer } = props;

  // See if they are subscribed or in a trial
  const subscription = getBrandSubscription(user);
  const subscriptionItem = subscription.items.find(i => i.offer?.id === offer.id);
  const { startedAt, isCancelled } = subscriptionItem || {};
  const isSubscribed = subscriptionItem && !isCancelled;
  const wasPreviouslyCancelled = subscriptionItem && !!isCancelled;

  // UI Helpers
  const { title, description, minDurationMonths } = offer || {};
  const image = offer.tileImage || offer.coverImage || 'https://static.shopmy.us/Subscriptions/Offer_Core_Tile.png';
  const daysUntilTrialEnds = subscriptionItem?.trialEndsAt ? moment(subscriptionItem.trialEndsAt).diff(moment(), 'days') : null;
  const isInTrial = subscriptionItem?.trialEndsAt && moment().isBefore(subscriptionItem.trialEndsAt);
  const canStartTrial = !wasPreviouslyCancelled && !!offer.offersTrial;

  // Actions
  const openModalOverlay = () => {
    props.openSubscriptionOfferModal({ params: { offer } });
  };

  // Edit Trial (admin only)
  const [isEditingTrial, setIsEditingTrial] = React.useState(false);
  const editTrialLength = async () => {
    const newEndDate = window.prompt('Enter new trial end date (YYYY-MM-DD)', moment(subscriptionItem?.trialEndsAt).format('YYYY-MM-DD'));
    if (!newEndDate) return;
    setIsEditingTrial(true);
    props.updateSubscriptionItem(subscriptionItem, { trialEndsAt: newEndDate }).then(() => {
      setIsEditingTrial(false);
    });
  };

  // Cancel subscription
  const [isCancelling, setIsCancelling] = React.useState(false);
  const cancelSubscriptionItem = async () => {
    const confirm = async () => {
      setIsCancelling(true);
      await props.cancelSubscriptionItem(subscriptionItem);
      setIsCancelling(false);
    };

    confirmAlert({
      title: 'Just confirming',
      message: isInTrial
        ? `You still have ${daysUntilTrialEnds} day${daysUntilTrialEnds === 1 ? '' : 's'} remaining in your trial. Are you sure you want to cancel?`
        : 'Are you sure you want to cancel your subscription?',
      buttons: [
        {
          label: 'Cancel',
          className: 'cancel',
          onClick: () => {}
        },
        {
          label: isInTrial ? 'Cancel Trial' : 'Cancel Subscription',
          onClick: confirm
        }
      ]
    });

    return;
  };

  // Determine if they can cancel
  let cannotCancel, cannotCancelReason;

  // If they are subscribed and the offer has a minimum duration that they have not yet reached, they cannot cancel
  if (!isInTrial && isSubscribed && minDurationMonths) {
    const dateTheyCanCancel = moment(subscriptionItem.startedAt)
      .add(offer.minDurationMonths, 'months')
      .endOf('day');
    if (moment().isBefore(dateTheyCanCancel)) {
      cannotCancel = true;
      cannotCancelReason = `You cannot cancel this subscription until ${dateTheyCanCancel.format('MMMM Do, YYYY')}. ${
        offer.title
      } has a minimum duration of ${minDurationMonths} month${minDurationMonths === 1 ? '' : 's'}.`;
    }
  }

  // If the offer is part of a bundle and they are subscribed to the bundle, they cannot cancel a single item
  if (!isInTrial && isSubscribed && subscription.bundle) {
    const isItemPartOfBundle = subscription.bundle.items.find(i => i.SubscriptionOffer_id === offer.id);
    if (isItemPartOfBundle) {
      cannotCancel = true;
      cannotCancelReason = `You cannot cancel ${offer.title} individually because it is part of the ${subscription.bundle.title} bundle. Please contact support to adjust your subscription.`;
    }
  }

  const actionOptions = [
    {
      display: isInTrial ? 'Cancel Trial' : 'Cancel Subscription',
      disabled: cannotCancel,
      tooltip: cannotCancelReason,
      icon: 'faTimes',
      isPerformingAction: isCancelling,
      onClick: cancelSubscriptionItem
    },
    ...(isInTrial && isAdminControlMode(ui)
      ? [
          {
            display: 'Extend/Edit Trial',
            disabled: false,
            tooltip: null,
            icon: 'faClock',
            isPerformingAction: isEditingTrial,
            onClick: editTrialLength
          }
        ]
      : [])
  ];

  const additionalClasses = { subscribed: isSubscribed, trial: isInTrial };

  if (isSubscribed) {
    const offerMessage = isInTrial
      ? `Trial ends in ${daysUntilTrialEnds} day${daysUntilTrialEnds === 1 ? '' : 's'}`
      : `Subscribed on ${moment(startedAt).format('MMMM Do, YYYY')}`;
    return (
      <div onClick={openModalOverlay} className={cn('subscription-offer-tile-basic', additionalClasses)}>
        <div className='content'>
          <div className='title'>{title}</div>
          <div className='description'>
            {isInTrial && <FontAwesomeIcon icon={faClock} />}
            {offerMessage}
          </div>
          <div className='actions-ellipsis-container'>
            <ActionsOverlay openUp containerClassName='actions-eliipsis' options={actionOptions}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </ActionsOverlay>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div onClick={openModalOverlay} className={cn('subscription-offer-tile', additionalClasses)}>
      <div className={cn('main', additionalClasses)}>
        <div className='title'>{title}</div>
        <div className='description'>{description}</div>
        {!isSubscribed && (
          <div className='learn-more-section'>
            <div className='learn-more'>
              Learn More
              <img src={icons.misc.arrowRight} alt='Arrow Right' />
            </div>
            {canStartTrial && <div className='offers-trial-btn'>Start Free Trial</div>}
          </div>
        )}
        {isInTrial ? (
          <div className='trial-update alert-msg'>
            <FontAwesomeIcon icon={faClock} />
            Trial ends in {daysUntilTrialEnds} day{daysUntilTrialEnds === 1 ? '' : 's'}
          </div>
        ) : null}
      </div>
      {!isSubscribed && (
        <div className='image-container'>
          <img src={image} alt={title} />
        </div>
      )}
      {isSubscribed && (
        <ActionsOverlay openUp containerClassName='actions-eliipsis' options={actionOptions}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </ActionsOverlay>
      )}
    </div>
  );
};

SubscriptionOfferTile.propTypes = {
  // From outside
  offer: PropTypes.object.isRequired,

  // From inside
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateSubscriptionItem: PropTypes.func.isRequired,
  openSubscriptionOfferModal: PropTypes.func.isRequired,
  subscribeToOffer: PropTypes.func.isRequired,
  cancelSubscriptionItem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  openSubscriptionOfferModal,
  subscribeToOffer,
  updateSubscriptionItem,
  cancelSubscriptionItem
})(SubscriptionOfferTile);
