import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import './EarningsPortalConsults.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '../General/Tooltip';

import { getPrettyPriceDisplay } from '../../Helpers/formatting';

const EarningsPortalConsults = props => {
  const { user, payoutsAreLoading } = props;

  const consultResults = useMemo(() => {
    const results = _.get(user, 'payouts.payout_summaries.consultResults', []);
    const orderedItems = _.orderBy(results, item => new Date(item.createdAt), 'desc');
    return orderedItems.map(item => {
      const { statusDisplay, paymentRefundedAt, isLocked, completedAt, isPaid } = item;
      return {
        ...item,
        statusDisplay: statusDisplay,
        infoTooltip: isPaid
          ? null
          : paymentRefundedAt
          ? 'Refunds can occur due to an extended delay in response time or a complaint on behalf of the client.'
          : isLocked
          ? 'This consultation payout is locked and will be paid to you at the end of the week.'
          : completedAt
          ? `This consultation payout is still in the two week refund window.`
          : null
      };
    });
  }, [user]);

  const hasMoreConsults = consultResults.length > 5;
  const gridTemplateColumns = '1fr 3fr 1fr 1fr';
  const [consultsVisible, setConsultsVisible] = useState(5);

  if (payoutsAreLoading || !consultResults.length) return null;
  return (
    <div className='earnings-portal-consults-outer'>
      <div className='earnings-portal-consults-inner'>
        <div className='earnings-portal-section-header'>
          <div>
            <div className='earnings-portal-section-title'>Consultations</div>
          </div>
        </div>

        <div className='earnings-portal-table'>
          <div className='earnings-portal-table-header' style={{ gridTemplateColumns }}>
            <div className='earnings-portal-table-cell'>Date</div>
            <div className='earnings-portal-table-cell'>Consult</div>
            <div className='earnings-portal-table-cell'>Amount Earned</div>
            <div className='earnings-portal-table-cell'>Status</div>
          </div>

          <div className='earnings-portal-table-body'>
            {consultResults.slice(0, consultsVisible).map((item, idx) => {
              const { title, stub, clientName, amountEarned, createdAt, statusDisplay, infoTooltip } = item;
              return (
                <div key={idx} className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                  <div className='earnings-portal-table-cell'>{moment(createdAt).format('MMM Do')}</div>
                  {title ? (
                    <a href={`/consults/results/${stub}`} target='_blank' rel='noopener nofollow noreferrer' className='earnings-portal-table-cell'>
                      {title || 'Consult'} for {clientName}
                    </a>
                  ) : (
                    <div className='earnings-portal-table-cell'>—</div>
                  )}
                  <div className='earnings-portal-table-cell'>{amountEarned ? getPrettyPriceDisplay(amountEarned) : '—'}</div>
                  <div className='earnings-portal-table-cell'>
                    {statusDisplay}
                    {infoTooltip && <Tooltip message={infoTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />}
                  </div>
                </div>
              );
            })}

            {(consultResults.length > 5 || !payoutsAreLoading) && (
              <div className='earnings-portal-table-row' style={{ gridTemplateColumns: '1fr' }}>
                <div className='show-more' onClick={() => (hasMoreConsults ? setConsultsVisible(consultsVisible + 5) : setConsultsVisible(5))}>
                  {hasMoreConsults ? 'Show More' : 'Show Less'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EarningsPortalConsults.propTypes = {
  user: PropTypes.object.isRequired,
  payoutsAreLoading: PropTypes.bool.isRequired
};

export default EarningsPortalConsults;
