import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './RequestResultsSection.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import RequestResult from './RequestResult';

const RequestResultsSection = props => {
  const {
    section,
    index,
    ui,
    user,
    talent,
    openArtistModal,
    openChatOverlay,
    lookbooks,
    opportunities,
    downloadGiftingRequests,
    isDownloading
  } = props;
  const { header, requests, onClick, showChatButton, largeFormat, defaultVisibleCount } = section;

  const [visibleRequestCount, setVisibleRequestCount] = useState(defaultVisibleCount || 27);
  const visibleRequests = requests.slice(0, visibleRequestCount);
  const seeAll = () => setVisibleRequestCount(requests.length);
  const seeMore = () => setVisibleRequestCount(visibleRequestCount + (defaultVisibleCount || 20));
  const seeLess = () => setVisibleRequestCount(defaultVisibleCount || 20);
  const canSeeMore = (defaultVisibleCount || 27) < requests.length;
  const canSeeLess = visibleRequestCount >= requests.length;
  const remainingCount = requests.length - visibleRequestCount;

  if (requests.length === 0) return null;
  return (
    <div className='results-section' key={header}>
      <div className='results-section-header'>
        <div className='text'>
          <div>{header}</div>
          <span className='request-count'>{requests.length}</span>
        </div>
        <div className='spacer'></div>
        <div className='download-button' onClick={() => downloadGiftingRequests(requests, index)}>
          <FontAwesomeIcon icon={faDownload} />
          <button className='btn btn-primary'>{isDownloading === index ? 'Downloading...' : 'Download'}</button>
        </div>
      </div>
      <div className='results-container'>
        {visibleRequests.map(request => {
          const lookbook = lookbooks.find(l => l.id === request.Lookbook_id);
          const opportunity = opportunities.find(o => o.id === request.Opportunity_id);

          return (
            <RequestResult
              ui={ui}
              user={user}
              talent={talent}
              featureRequired={section.featureRequired}
              openArtistModal={openArtistModal}
              openChatOverlay={openChatOverlay}
              removeSamplesRequest={props.removeSamplesRequest}
              removeOpportunityRequest={props.removeOpportunityRequest}
              updateRequest={props.updateRequest}
              updateOpportunityRequest={props.updateOpportunityRequest}
              openRequestModal={props.openRequestModal}
              request={request}
              key={`all-requests-${request.id}`}
              markSent={onClick || (() => {})}
              lookbook={lookbook}
              opportunity={opportunity}
              showChatButton={showChatButton}
              largeFormat={largeFormat}
              signedInUser={user}
            />
          );
        })}

        {canSeeMore && (
          <div className='see-more-button-container'>
            {canSeeLess ? (
              <div className='see-more-button' onClick={seeLess}>
                <span>See Less</span>
                <FontAwesomeIcon icon={faChevronUp} />
              </div>
            ) : (
              <div className='see-more-button' onClick={seeMore}>
                <span>{canSeeLess ? 'See Less' : 'View More'}</span>
                <span className='remaining-request-count'>{remainingCount}</span>
              </div>
            )}
            {!canSeeLess && (
              <div className='see-all-button' onClick={seeAll}>
                <span>See All</span>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

RequestResultsSection.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  ui: PropTypes.object,
  user: PropTypes.object.isRequired,
  talent: PropTypes.array,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  lookbooks: PropTypes.array.isRequired,
  opportunities: PropTypes.array.isRequired,
  downloadGiftingRequests: PropTypes.func.isRequired,
  isDownloading: PropTypes.number.isRequired,
  removeSamplesRequest: PropTypes.func.isRequired,
  removeOpportunityRequest: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  updateOpportunityRequest: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired
};

export default RequestResultsSection;
