import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import './ManageUsersMenu.scss';

import Loader from '../Loader/Loader';

import { isCurrentlyManaging, getManagerManages, getCurrentManager, getNotificationCountForManagesUser } from '../../Helpers/manager_helpers';
import { getManages, getProfileFromObject, getUserId } from '../../Helpers/user_helpers';
import { getAndRemoveUrlParam, scrollToTop } from '../../Helpers/helpers';
import { getInitialsForUser } from '../../Helpers/formatting';

const ManageUsersMenu = props => {
  const { close, user, manager, active, isScrolled } = props;
  const [searchVal, setSearchVal] = useState();
  const [isSwitchingToUser, setSwitchingToUser] = useState(null);

  let realUser, managesUsers;
  if (isCurrentlyManaging(manager)) {
    realUser = getCurrentManager(manager);
    managesUsers = getManagerManages(manager).map(m => m.user);
  } else {
    realUser = getProfileFromObject(user);
    managesUsers = getManages(user).map(m => m.user);
  }
  const isSearchMatch = m =>
    !searchVal || m.name.toLowerCase().includes(searchVal.toLowerCase()) || m.username.toLowerCase().includes(searchVal.toLowerCase());

  const results = [...managesUsers, realUser];
  const filteredResults = results.filter(isSearchMatch);
  const hasOneFileredResult = results.length > 1 && filteredResults.length === 1;

  const selectResult = async resultUser => {
    setSwitchingToUser(resultUser);
    const switchingBackToManager = realUser.id === resultUser.id;
    const newManagedUser = switchingBackToManager ? null : resultUser;

    // Handle Redirects
    if (newManagedUser) {
      // props.history.push(`/${newManagedUser.username}`);
    } else if (realUser.isManager) {
      props.history.push(`/settings`);
    }

    await props.selectUserToManage(newManagedUser);
    scrollToTop();
    await close();
  };

  const clickEnter = e => {
    e.stopPropagation();
    e.preventDefault();
    if (hasOneFileredResult) selectResult(filteredResults[0]);
  };

  // Allow deep linking to selection
  const managinguseridraw = getAndRemoveUrlParam('managinguserid');
  if (managinguseridraw) {
    const managinguserid = parseInt(managinguseridraw);
    const deepLinkedUserManages = managesUsers.find(u => u.id === managinguserid);
    const deepLinkedUserReal = realUser?.id === managinguserid ? realUser : null;
    const deepLinkedUser = deepLinkedUserManages || deepLinkedUserReal;
    const isSelected = getUserId(user) === managinguserid;
    !isSelected && setTimeout(() => selectResult(deepLinkedUser), 1000);
  }

  return (
    <>
      <div onClick={close} className={cn('manage-users-menu-fade-container', { active })} />
      <div className={cn('manage-users-menu-outer-container', { active, scrolled: isScrolled })}>
        <div className='manages-menu-container'>
          {active && results.length >= 5 && (
            <form onSubmit={clickEnter}>
              <input
                className='search-input'
                placeholder='Search Creators'
                onChange={({ target }) => setSearchVal(target.value)}
                value={searchVal}
                autoFocus
              />
            </form>
          )}
          {filteredResults.map(resultUser => {
            const { image, name, isBanned, createdAt, id } = resultUser;
            const isYou = realUser.id === id;
            const isBannedManagedAccount = isBanned && !isYou;
            const isSelected = getUserId(user) === id;
            const switchingTo = isSwitchingToUser?.id === id;
            const switchingToOther = isSwitchingToUser && !switchingTo;
            const notificationCount = getNotificationCountForManagesUser(resultUser, manager);
            const select = () => {
              if (isBannedManagedAccount) return window.ALERT.warn("This user's account is currently under review.");
              !isSelected && selectResult(resultUser);
            };
            const score = Math.floor(resultUser.tier?.score || 0);

            return (
              <div
                onClick={select}
                key={id}
                className={cn('user-container', {
                  selected: isSelected,
                  'one-filtered-result': hasOneFileredResult,
                  disabled: isBannedManagedAccount
                })}
              >
                <div className='main'>
                  {image ? (
                    <img alt='Current User' className='profile-image' src={image} />
                  ) : (
                    <div className='initials'>{getInitialsForUser(resultUser)}</div>
                  )}
                  <div className='data'>
                    <div className='name'>{name}</div>
                    {isBannedManagedAccount ? (
                      <div className='date'>This account is currently under review.</div>
                    ) : isYou ? (
                      <div className='date'>Your Account{isSelected ? '' : ' (Click to Refresh Talent List)'}</div>
                    ) : (
                      <div className='date'>
                        {score} • Joined {moment(createdAt).format('MMMM Do, YYYY')}
                      </div>
                    )}
                  </div>
                </div>

                <div className='actions'>
                  {switchingTo ? (
                    <Loader size={40} />
                  ) : notificationCount && !isSelected && !switchingTo ? (
                    <div className='notification-count'>{notificationCount}</div>
                  ) : (
                    <div className={cn('action', { selected: isSelected, 'switching-to-other': switchingToOther })}>
                      {switchingTo ? 'Selecting...' : isSelected ? 'Active' : hasOneFileredResult ? 'Press Enter' : ''}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className='close-menu-icon' onClick={close}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </>
  );
};

ManageUsersMenu.propTypes = {
  user: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  isScrolled: PropTypes.bool.isRequired,
  selectUserToManage: PropTypes.func.isRequired
};

export default withRouter(withRouter(ManageUsersMenu));
