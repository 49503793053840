import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert'; // Import
import cn from 'classnames';
import './EarningsPortalPaymentAccounts.scss';

import { updateCurrentUser } from '../../Actions/UserActions';
import { linkPaypalAccount, unlinkPaymentAccount, linkInvoiceEmailAccount } from '../../Actions/PayoutActions';

import { getUserId, getUserPaymentAccounts, isBrand, isBanned, getStripeAccountId } from '../../Helpers/user_helpers';

const EarningsPortalPaymentAccounts = props => {
  const { user, linkPaypalAccount, unlinkPaymentAccount, updateCurrentUser } = props;

  const paymentAccounts = getUserPaymentAccounts(user);
  const stripeAccountId = getStripeAccountId(user);
  const gridTemplateColumns = '1fr 1fr 1fr';

  const [isUnlinkingAccount, setIsUnlinkingAccount] = useState(false);
  const [isLinkingPaypal, setIsLinkingPaypal] = useState(false);
  const [paypalLinkComponent, setPaypalLinkComponent] = useState(null);

  useEffect(() => {
    loadPaypal();
  }, []);

  const loadPaypal = (idx = 0) => {
    const script = document.createElement('script');
    script.src = 'https://www.paypalobjects.com/payouts/js/payouts_aac.js';
    script.setAttribute('data-namespace', `paupal_sdk${idx || ''}`);

    script.onload = () => {
      window.PayoutsAAC = window.paypal?.PayoutsAAC || window.PayoutsAAC; // We've seen issues of double loading so need to store it here
      const scriptSubscription = document.createElement('script');
      scriptSubscription.src = window.__IS_PROD__
        ? 'https://www.paypal.com/sdk/js?client-id=AcyzI0djoatYzkD8QnBVErRCeGfsdzuW1nHgbAWYcl0VYet1MHmd_N3ZX-QZPQob7r5Qx13fu__p9CJv&vault=true&intent=subscription'
        : 'https://www.paypal.com/sdk/js?client-id=AV8LJVWzjxYCtQ1tVwyaPL3XJFnaMdYro-_Fdc7San0uwh6MZARYkhYnvYKWZ0laBFDhdo3-go1ST0fH&vault=true&intent=subscription';
      scriptSubscription.setAttribute('data-sdk-integration-source', 'button');

      window.paypal = null;
      scriptSubscription.onload = async () => {
        const PayoutsAAC = window.paypal.PayoutsAAC || window.PayoutsAAC;
        const AACComponent = PayoutsAAC?.driver('react', { React, ReactDOM });

        if (!AACComponent) {
          window.ALERT.warn('Issue loading!!');
          return null;
        }

        setPaypalLinkComponent(
          <AACComponent
            clientId={{
              production: 'AcyzI0djoatYzkD8QnBVErRCeGfsdzuW1nHgbAWYcl0VYet1MHmd_N3ZX-QZPQob7r5Qx13fu__p9CJv',
              sandbox: 'AV8LJVWzjxYCtQ1tVwyaPL3XJFnaMdYro-_Fdc7San0uwh6MZARYkhYnvYKWZ0laBFDhdo3-go1ST0fH'
            }}
            style={{ layout: 'vertical', color: 'blue', shape: 'rect', label: 'paypal', display: 'none' }}
            merchantId='BZ2U36U3HHSL6'
            env={window.__IS_PROD__ ? 'production' : 'sandbox'}
            pageType='login'
            onLogin={linkPayPal}
          />
        );
      };
      document.body.appendChild(scriptSubscription);
    };
    document.body.appendChild(script);
  };

  const linkPayPal = response => {
    const code = _.get(response, 'body.code');
    if (isBanned(user)) {
      window.ALERT.warn(`Your account is currently under review and cannot link a PayPal account.`);
    } else if (response.err || !code) {
      window.ALERT.error(
        'PayPal is current experiencing outages on their account linking. For now, please email the PayPal email from your account to us at team@shopmy.us and we will manually send you payments. We are working with PayPal to resolve the issue and apologize for the extra steps.',
        { hideAfter: 10 }
      );
    } else {
      setIsLinkingPaypal(true);
      linkPaypalAccount(user, code).then(() => {
        setIsLinkingPaypal(false);
      });
    }
  };

  const unlinkAccount = account => {
    if (isUnlinkingAccount) return;
    setIsUnlinkingAccount(true);
    unlinkPaymentAccount(account).then(() => setIsUnlinkingAccount(false));
  };

  const getDisplaySource = source =>
    ({
      INVOICE_EMAIL: 'Email To Send Invoices For Commissions',
      PAYPAL_SUBSCRIPTION: 'Basic Subscription PayPal',
      PAYPAL_SUBSCRIPTION_PREMIUM: 'Premium Subscription PayPal'
    }[source] || source);

  const tryLinking = () => {
    window.ALERT.warn(
      `If no popup appears when clicking this button, please send us a chat message with your PayPal email and we will connect it manually.`
    );
  };

  const unlinkStripe = () => {
    confirmAlert({
      title: 'Just confirming',
      message: 'Are you sure you want to unlink your Stripe Account?',
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => {
            updateCurrentUser({ stripeAccountId: null, stripeOnboardingComplete: false }, getUserId(user));
          }
        }
      ]
    });
  };

  return (
    <div className='earnings-portal-payment-accounts-outer'>
      <div className='earnings-portal-payment-accounts-inner'>
        <div className='earnings-portal-section-header'>
          <div>
            <div className='earnings-portal-section-title'>Linked Accounts</div>
            <div className='earnings-portal-section-subtitle'>
              Please link your PayPal account below. If you cannot successfully link your PayPal account, please go to{' '}
              <Link to='settings'>account settings</Link> to link a bank account using Stripe.
            </div>
          </div>
        </div>

        <div className='earnings-portal-table'>
          <div className='earnings-portal-table-header' style={{ gridTemplateColumns }}>
            <div className='earnings-portal-table-cell'>Type</div>
            <div className='earnings-portal-table-cell'>Account</div>
            <div className='earnings-portal-table-cell'>Action</div>
          </div>

          <div className='earnings-portal-table-body'>
            {stripeAccountId && (
              <div className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                <div className='earnings-portal-table-cell'>STRIPE</div>
                <div className='earnings-portal-table-cell'>{stripeAccountId}</div>
                <div className='earnings-portal-table-cell btn' onClick={unlinkStripe}>
                  REMOVE
                </div>
              </div>
            )}

            {_.map(paymentAccounts, account => {
              const { paypalEmail, email, address, paypalSubscriptionId, source } = account;
              const isSubscription = _.includes(source, 'SUBSCRIPTION');
              return (
                <div key={account.id} className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                  <div className='earnings-portal-table-cell'>{getDisplaySource(source)}</div>
                  <div className='earnings-portal-table-cell'>{paypalEmail || email || address || paypalSubscriptionId}</div>
                  <div
                    onClick={() =>
                      isSubscription && !window.__IS_DEV__
                        ? window.ALERT.warn('Please reach out to us at team@shopmy.us to cancel your subscription.')
                        : unlinkAccount(account)
                    }
                    className='earnings-portal-table-cell btn'
                  >
                    REMOVE
                  </div>
                </div>
              );
            })}

            {!_.get(paymentAccounts, 'length') && (
              <div className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                <div className='earnings-portal-table-cell'>{isBrand(user) ? 'Commission Invoices - PayPal' : 'PAYPAL'}</div>
                <div className='earnings-portal-table-cell'>-</div>
                <div
                  className={cn('earnings-portal-table-cell', 'btn', !!paypalLinkComponent ? 'loaded' : 'not-loaded')}
                  onClick={() => {
                    if (!paypalLinkComponent) {
                      window.ALERT.warn(
                        `Please refresh the page to see if the red background goes away! If the background of the button remains red after refreshing, please contact a member of our team by chat or by email at team@shopmy.us to provide your PayPal email address so we can pay you on the first of each month.`,
                        { hideAfter: 12 }
                      );
                    }
                  }}
                >
                  {isLinkingPaypal ? (
                    <span>LINKING...</span>
                  ) : (
                    <>
                      <div onClick={tryLinking}>LINK ACCOUNT</div>
                      <div className='paypal-link-btn'>{paypalLinkComponent}</div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EarningsPortalPaymentAccounts.propTypes = {
  user: PropTypes.object.isRequired,
  linkPaypalAccount: PropTypes.func.isRequired,
  unlinkPaymentAccount: PropTypes.func.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
  linkInvoiceEmailAccount: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  linkPaypalAccount,
  unlinkPaymentAccount,
  updateCurrentUser,
  linkInvoiceEmailAccount
})(EarningsPortalPaymentAccounts);
