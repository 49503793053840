import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './OnboardingModal.scss';

import { getOnboardingTags } from '../../APIClient/tags';
import {
  toggleBodyScrollLock,
  setSpotlightKey,
  clearSpotlightKey,
  setShowSpotlightOptionsUi,
  setTermsAndConditionsVisible
} from '../../Actions/UIActions';
import { updateUserSettings, connectInstagram, updateCurrentUser, syncUserTier, addTagsToUser, deleteTagsForUser } from '../../Actions/UserActions';

import OnboardingPanelIntro from './Panels/OnboardingPanelIntro';
import OnboardingPanelBio from './Panels/OnboardingPanelBio';
import OnboardingPanelSocial from './Panels/OnboardingPanelSocial';
import OnboardingPanelTags from './Panels/OnboardingPanelTags';
import OnboardingPanelTiers from './Panels/OnboardingPanelTiers';
import OnboardingPanelTasks from './Panels/OnboardingPanelTasks';
import OnboardingPanelTermsAndPrivacy from './Panels/OnboardingPanelTermsAndPrivacy';

import ArrowButton from '../General/Buttons/ArrowButton';

import { spotlightKeys, getUrlForSpotlightKey, showingSpotlightTasksModal, getActionForSpotlightKey } from '../../Helpers/spotlight_helpers';
import { getUsername, hasAgreedToPrivacyPolicy, hasAgreedToTermsAndConditions, hasCompletedOnboarding } from '../../Helpers/user_helpers';
import { getUrlParam, setUrlParam, removeUrlParam, dontBubble } from '../../Helpers/helpers';
import { intitializeIntercom } from '../../Helpers/chat_helpers';
import { ONBOARDING_MODAL_TABS, ONBOARDING_MODAL_TABS_ORDER } from '../../Helpers/constants';
import { isTermsAndConditionsModalVisible } from '../../Helpers/ui_helpers';

const OnboardingModal = props => {
  const {
    user,
    ui,
    history,
    onlyShowTab,
    toggleBodyScrollLock,
    setShowSpotlightOptionsUi,
    updateCurrentUser,
    setSpotlightKey,
    updateUserSettings
  } = props;

  // Block navigation if subpanels request it or they try enough
  const [cannotProceedMessage, setCannotProceedMessage] = useState(null);
  const [numCloseAttempts, setNumCloseAttempts] = useState(0);

  // Allow selection of a highlight task with redirects
  const [selectedTaskKey, setSelectedTaskKey] = useState(null);
  const [selectedTaskPath, setSelectedTaskPath] = useState(null);

  // Handle Tabs
  const showSpotlightPanelOnly = onlyShowTab === ONBOARDING_MODAL_TABS.TASKS;
  const tabs = onlyShowTab ? [onlyShowTab] : ONBOARDING_MODAL_TABS_ORDER;

  const [tab, setTab] = useState(onlyShowTab || getUrlParam('tab') || tabs[0]);
  const tabIndex = tabs.indexOf(tab);
  const isFinalTab = tab === _.last(tabs);
  const containerRef = useRef(null);
  const scrollToTop = () => containerRef.current && containerRef.current.scrollTo(0, 0);
  const goPrevTab = () => {
    setCannotProceedMessage('');
    scrollToTop();
    setTab(tabs[tabIndex - 1]);
    window.__ADD_EVENT__(`Onboarding - Previous Tab`, { tab: tabs[tabIndex - 1], user });
  };

  const goNextTab = () => {
    if (cannotProceedMessage) return window.ALERT.warn(cannotProceedMessage);
    scrollToTop();
    setTab(tabs[tabIndex + 1]);
    window.__ADD_EVENT__(`Onboarding - Next Tab`, { tab: tabs[tabIndex + 1], user });
  };

  // Ensure we set the header param when they change tab
  useEffect(() => {
    setUrlParam('tab', tab);
  }, [tab]);

  // Handle UI treatment
  const [shrunkToCorner, setShrunkToCorner] = useState(false);
  const shrinkModal = async () => {
    setShrunkToCorner(true);
    await new Promise(r => setTimeout(r, 300)); // Keep this in sync with .shrunk sccss
  };

  const closeModal = async () => {
    if (cannotProceedMessage) return window.ALERT.warn(cannotProceedMessage);
    if (!onlyShowTab || showSpotlightPanelOnly) {
      // Shrink modal to show where it lives
      await shrinkModal();

      if (selectedTaskKey) {
        setSpotlightKey(selectedTaskKey);

        // Perform spotlight redirect
        const redirectUrl = getUrlForSpotlightKey(selectedTaskKey, getUsername(user));
        redirectUrl && history.push(redirectUrl);
      }

      if (selectedTaskPath) {
        history.push(selectedTaskPath);
      }
    }

    // Set up intercom if needed
    !onlyShowTab && !isMobile && intitializeIntercom(user);

    // Perform action if needed
    const actionForSpotlightKey = getActionForSpotlightKey(selectedTaskKey);
    actionForSpotlightKey && actionForSpotlightKey();

    // Log Eventing
    showSpotlightPanelOnly
      ? window.__ADD_EVENT__(`Spotlight - Show Spotlight`, { user, spotlight: selectedTaskKey })
      : window.__ADD_EVENT__(`Onboarding - Complete Onboarding`, { user, spotlight: selectedTaskKey });

    if (!onlyShowTab) {
      // closing onboarding modal, mark onboarding complete
      await updateUserSettings({ hasCompletedOnboarding: true });
    }

    // Close the panel - in the case we are forcing it open via spotlight
    showingSpotlightTasksModal(ui) && setShowSpotlightOptionsUi(false);

    if (isTermsAndConditionsModalVisible(ui)) {
      props.setTermsAndConditionsVisible(false);
    }

    // remove tab URL param
    removeUrlParam('tab');
  };

  const checkBlockingCloseRequirements = () => {
    if (!hasAgreedToPrivacyPolicy(user) || !hasAgreedToTermsAndConditions(user)) {
      if (tab !== ONBOARDING_MODAL_TABS.TERMS_AND_PRIVACY) {
        setTab(ONBOARDING_MODAL_TABS.TERMS_AND_PRIVACY);
      }

      return window.ALERT.warn('Please review the ShopMy Terms and Conditions to continue.');
    }

    return null;
  };

  const closeModalWithoutSpotlight = () => {
    if (showSpotlightPanelOnly) {
      setShowSpotlightOptionsUi(false);
      removeUrlParam('tab');
    } else if (numCloseAttempts > 2) {
      const blockingCloseRequirements = checkBlockingCloseRequirements();

      if (!blockingCloseRequirements) {
        closeModal();
      }
    } else {
      setSelectedTaskKey(null);
      setNumCloseAttempts(numCloseAttempts + 1);
    }
  };

  const handleTermsOrPrivacyUpdate = async settingsUpdates => {
    await updateUserSettings(settingsUpdates);
  };

  // Ensure they cannot scroll behind the modal
  useEffect(() => {
    setTimeout(() => toggleBodyScrollLock(true), 1000); // let modal load
    return () => toggleBodyScrollLock(false);
  }, [toggleBodyScrollLock]);

  // Fetch initial tags for that tab
  const [onboardingTags, setOnboardingTags] = useState([]);
  useEffect(() => {
    getOnboardingTags().then(tags => setOnboardingTags(tags));
  }, [setOnboardingTags]);

  return (
    <div onClick={closeModalWithoutSpotlight} className='onboarding-modal-outer-container'>
      <div onClick={dontBubble} className={cn('onboarding-modal-inner-container', { shrunk: shrunkToCorner })}>
        <div ref={containerRef} className='modal-body'>
          {tab === ONBOARDING_MODAL_TABS.INTRO && <OnboardingPanelIntro user={user} />}
          {tab === ONBOARDING_MODAL_TABS.SOCIAL && (
            <OnboardingPanelSocial
              setCannotProceedMessage={setCannotProceedMessage}
              user={user}
              connectInstagram={props.connectInstagram}
              updateCurrentUser={updateCurrentUser}
            />
          )}
          {tab === ONBOARDING_MODAL_TABS.BIO && <OnboardingPanelBio user={user} updateCurrentUser={updateCurrentUser} />}
          {tab === ONBOARDING_MODAL_TABS.TERMS_AND_PRIVACY && (
            <OnboardingPanelTermsAndPrivacy
              user={user}
              handleUpdate={handleTermsOrPrivacyUpdate}
              isOnlyPanel={onlyShowTab === ONBOARDING_MODAL_TABS.TERMS_AND_PRIVACY}
              setCannotProceedMessage={setCannotProceedMessage}
            />
          )}
          {tab === ONBOARDING_MODAL_TABS.TAGS && (
            <OnboardingPanelTags
              addTagsToUser={props.addTagsToUser}
              deleteTagsForUser={props.deleteTagsForUser}
              onboardingTags={onboardingTags}
              user={user}
            />
          )}
          {tab === ONBOARDING_MODAL_TABS.TIERS && <OnboardingPanelTiers syncUserTier={props.syncUserTier} user={user} />}
          {tab === ONBOARDING_MODAL_TABS.TASKS && (
            <OnboardingPanelTasks
              user={user}
              isOnlyPanel={onlyShowTab === ONBOARDING_MODAL_TABS.TASKS}
              selectedTaskKey={selectedTaskKey}
              selectedTaskPath={selectedTaskPath}
              setSelectedTaskKey={setSelectedTaskKey}
              setSelectedTaskPath={setSelectedTaskPath}
              closeModalWithoutSpotlight={closeModalWithoutSpotlight}
            />
          )}
        </div>
        <div className={cn('modal-footer', { app: window.__IS_APP__ })}>
          {tabs.length > 1 ? (
            <div className='dots'>
              {tabs.map(t => (
                <div key={t} onClick={() => setTab(t)} className={cn('dot', { active: t === tab })} />
              ))}
            </div>
          ) : (
            <div className='empty' />
          )}
          <div className='nav-actions'>
            {tabIndex > 0 && <ArrowButton onClick={goPrevTab} onlyText displayText='Back' />}
            {isFinalTab ? (
              <ArrowButton
                disabled={!!cannotProceedMessage}
                onClick={closeModal}
                displayText={showSpotlightPanelOnly ? 'See How It Works' : 'Done'}
              />
            ) : (
              <ArrowButton disabled={!!cannotProceedMessage} onClick={goNextTab} displayText='Next' />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

OnboardingModal.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired,
  connectInstagram: PropTypes.func.isRequired,
  addTagsToUser: PropTypes.func.isRequired,
  syncUserTier: PropTypes.func.isRequired,
  deleteTagsForUser: PropTypes.func.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
  setShowSpotlightOptionsUi: PropTypes.func.isRequired,
  setSpotlightKey: PropTypes.func.isRequired,
  clearSpotlightKey: PropTypes.func.isRequired,

  // If we are just leveraging onboarding modal only for a specific tab
  onlyShowTab: PropTypes.string
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  updateUserSettings,
  toggleBodyScrollLock,
  addTagsToUser,
  deleteTagsForUser,
  connectInstagram,
  syncUserTier,
  updateCurrentUser,
  setShowSpotlightOptionsUi,
  setSpotlightKey,
  clearSpotlightKey,
  setTermsAndConditionsVisible
})(withRouter(OnboardingModal));
