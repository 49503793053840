import APIClient from './index';
import _ from 'lodash';

import { getBrandId } from '../Helpers/user_helpers';

export const fetchBrandDeveloperKey = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Brands/developer_key?Brand_id=${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const rotateBrandDeveloperKey = Brand_id =>
  APIClient.post(`/Brands/developer_key/rotate`, { Brand_id })
    .then(response => response.data)
    .catch(error => _.get(error, 'response.data.error'));

export const setCommissionRate = (user, userid, rate, rate_returning, brandMessage = '') => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/CustomRates`, {
      User_id: userid,
      Brand_id: getBrandId(user),
      rate,
      rate_returning,
      brandMessage
    })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const setCode = ({
  id,
  user,
  userid,
  rate,
  isPercentage,
  displayText,
  shopifyCodeType,
  shopifyCollectionIds,
  brandIntegrationIds,
  brandMessage = ''
}) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/CustomCodes`, {
      id,
      rate,
      isPercentage,
      displayText,
      brandMessage,
      shopifyCodeType,
      shopifyCollectionIds,
      brandIntegrationIds,
      User_id: userid,
      Brand_id: getBrandId(user)
    })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateCode = (code, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/CustomCodes/${code.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const fetchCustomRates = id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/CustomRates/all_rates/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const discoverBrands = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Brands/discover?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const getBrand = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Brands/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const updateBrand = (brand, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Brands/${brand.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const logBrandMessage = message => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Brands/log_message`, { message })
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getInvoicesSummary = user => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/AffiliateInvoices/summary`, { Brand_id: getBrandId(user) })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getContractInvoices = user => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/ContractInvoices?Brand_id=${getBrandId(user)}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBudgetInvoices = user => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandBudgetInvoices?Brand_id=${getBrandId(user)}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateBrandBudgetInvoiceById = (id, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/BrandBudgetInvoices/${id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getCreatorBonuses = user => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Brands/${getBrandId(user)}/get_bonuses`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const revertCreatorBonus = data => {
  return new Promise((resolve, reject) => {
    APIClient.post('/BrandBudgets/revert_bonus', data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandRemaining = user => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Brands/remaining?Brand_id=${getBrandId(user)}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const searchArtists = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Brands/artist_search`, params)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const inviteTalent = (user, emails) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Brands/invite_talent`, { Brand_id: getBrandId(user), emails })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const sendSamplesRequest = (Brand_id, artist, extra = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Requests`, { Brand_id, User_id: artist.id, type: 'SAMPLES', initiator_type: 'brand', ...extra })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateRequest = (request, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Requests/${request.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteSamplesRequest = request => {
  return new Promise((resolve, reject) => {
    return APIClient.delete(`/Requests/${request.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.data));
  });
};

export const isUserBrandPromoter = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Brands/is_promoter`, params)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateBrandSettings = (settings, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/BrandSettings/${settings.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getFollowupTemplates = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Brands/followup_templates?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const refreshBrandTier = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BrandTiers/refresh_tier`, { Brand_id })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandTierSnapshot = (Brand_id, date) => {
  const params = { Brand_id, date };
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandTiers/snapshot?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandTierSnapshots = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandTiers/snapshots/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getExamplePrograms = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandTiers/example_programs`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandExampleProgram = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandTiers/example_program/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandRetailers = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Brands/get_retailers/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const sendBonusToTalent = ({ Brand_id, User_id, amount, additionalMessage }) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Brands/send_bonus`, { Brand_id, User_id, amount, additionalMessage })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getCompetitorTargets = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Brands/competitor_targets/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
